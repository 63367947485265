import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "./demo.css";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { v4 as uuidv4 } from "uuid";
import config from "../../config";
import { CSVLink } from "react-csv";
import DownloadCSVButton from "./downloadCSV";
const Test = () => {
  const tableRef = useRef(null);
  const [formData, setFormData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [download, setDownload] = useState(false);
  const UserDetails = sessionStorage.getItem("LoginUser");
  const UserDetailsData = JSON.parse(UserDetails);
  // Function to fetch data from the server
  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/householdData`);
      if (Array.isArray(response.data.recordset)) {
        const formattedData = response.data.recordset
          .map((item) => {
            const parsedFormData = item.FORM_DATA
              ? JSON.parse(item.FORM_DATA)
              : null;

            return {
              ...item,
              FORM_DATA: parsedFormData,
              userId: uuidv4(),
              interviewerId: uuidv4(),
            };
          })
          .filter((item) => item.FORM_DATA);

        const filteredData = formattedData.filter((item) => {
          if (UserDetailsData.ROLE === "0") {
            // For ROLE "0", return all items
            // console.log("FORM_DATA (ROLE 0):", item);
            return true;
          } else {
            // For other roles, filter based on city match
            if (item.FORM_DATA.generalInformation) {
              const generalInformation = item.FORM_DATA
                ? JSON.parse(item.FORM_DATA.generalInformation)
                : null;
              if (generalInformation && generalInformation.districtName) {
                if (UserDetailsData.CITY === generalInformation.districtName) {
                  // console.log("FORM_DATA (City Match):", item);
                  return true;
                }
              }
            }
          }

          // If item does not match the conditions, it will not be included in filteredData
          return false;
        });

        console.log("Filtered Data:", filteredData);
        setFormData(filteredData);
      } else {
        console.error("Invalid data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching household data:", error);
    }
  };

  // Fetch data once when component mounts
  useEffect(() => {
    if (
      UserDetailsData.ROLE === "0" ||
      UserDetailsData.ROLE === "10" ||
      UserDetailsData.ROLE === "20"
    ) {
      setDownload(true);
    }
    fetchData();
  }, []);

  // Function to handle filtering by date range
  // const handleFilterByDateRange = async () => {
  //   try {
  //     const response = await axios.get(`${config.baseUrl}/householdData`);
  //     if (Array.isArray(response.data.recordset)) {
  //       const formattedData = response.data.recordset
  //         .map((item) => ({
  //           ...item,
  //           FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : null,
  //           userId: uuidv4(),
  //           interviewerId: uuidv4(),
  //         }))
  //         .filter((item) => item.FORM_DATA);

  //       // Filter the data based on date range
  //       const filteredData = formattedData.filter((item) => {
  //         const itemDate = new Date(item.STATUS_DATE);
  //         return itemDate >= startDate && itemDate <= endDate;
  //       });

  //       // Update formData state with filtered data
  //       console.log("filterData based on date ",filteredData)
  //       setFormData(filteredData);
  //     } else {
  //       console.error("Invalid data format:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching or filtering household data:", error);
  //   }
  // };

  // const handleFilterByDateRange = async () => {
  //   try {
  //     const response = await axios.get(`${config.baseUrl}/householdData`);
  //     if (Array.isArray(response.data.recordset)) {
  //       const formattedData = response.data.recordset
  //         .map((item) => ({
  //           ...item,
  //           FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : null,
  //           userId: uuidv4(),
  //           interviewerId: uuidv4(),
  //         }))
  //         .filter((item) => item.FORM_DATA);

  //       // Filter the data based on date range
  //       const filteredData = formattedData.filter((item) => {
  //         const itemDate = new Date(item.STATUS_DATE);
  //         const isSameDate = startDate.toDateString() === endDate.toDateString();
  //         return isSameDate
  //           ? itemDate.toDateString() === startDate.toDateString()
  //           : itemDate >= startDate && itemDate <= endDate;
  //       });

  //       // Update formData state with filtered data
  //       console.log("filterData based on date ", filteredData);
  //       setFormData(filteredData);
  //     } else {
  //       console.error("Invalid data format:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching or filtering household data:", error);
  //   }
  // };
  const handleFilterByDateRange = async () => {
    try {
      const response = await axios.get(`${config.baseUrl}/householdData`);
      if (Array.isArray(response.data.recordset)) {
        const formattedData = response.data.recordset
          .map((item) => ({
            ...item,
            FORM_DATA: item.FORM_DATA ? JSON.parse(item.FORM_DATA) : null,
            userId: uuidv4(),
            interviewerId: uuidv4(),
          }))
          .filter((item) => item.FORM_DATA);

        // Add one day to the end date to make it inclusive
        const inclusiveEndDate = new Date(endDate);
        inclusiveEndDate.setDate(inclusiveEndDate.getDate() + 1);

        // Filter the data based on date range
        const filteredData = formattedData.filter((item) => {
          const itemDate = new Date(item.STATUS_DATE);
          const isSameDate =
            startDate.toDateString() === endDate.toDateString();
          return isSameDate
            ? itemDate.toDateString() === startDate.toDateString()
            : itemDate >= startDate && itemDate < inclusiveEndDate;
        });

        // Update formData state with filtered data
        console.log("Filtered data based on date range:", filteredData);
        setFormData(filteredData);
      } else {
        console.error("Invalid data format:", response.data);
      }
    } catch (error) {
      console.error("Error fetching or filtering household data:", error);
    }
  };

  // Validation for enabling Apply Date Range button
  const isApplyButtonDisabled = !startDate || !endDate;

  const filler = "999";
  const filler2 = "777";

  const myFunction = (FORM_DATA, HealthType, itemsName, joinitem) => {
    // console.log(itemsName)
    if (FORM_DATA !== null && FORM_DATA.houseHoldMemberModel !== undefined) {
      return Array.isArray(FORM_DATA.houseHoldMemberModel)
        ? FORM_DATA.houseHoldMemberModel
            .map((member) => {
              if (member !== undefined && member[HealthType] !== undefined) {
                // console.log(member);
                const currentHealth = JSON.parse(member[HealthType]);
                return currentHealth &&
                  currentHealth[itemsName] != null &&
                  currentHealth[itemsName] != ""
                  ? currentHealth[itemsName]
                  : filler2; // Assuming filler2 is defined somewhere
              } else {
                return filler;
              }
            })
            .join(joinitem === "," ? "," : "\n")
        : filler;
    }
  };

  // Define columns
  // Define columns
  const columns = [
    {
      label: "User ID",
      field: "userId",
      sort: "asc",
    },
    {
      label: "Interviewer Name",
      field: "interviewerName",
      sort: "asc",
    },
    {
      label: "Interviewer ID",
      field: "interviewerId",
      sort: "asc",
    },

    // {
    //   label: "State",
    //   field: "state",
    //   sort: "asc",
    // },
    {
      label: "District",
      field: "district",
      sort: "asc",
    },
    {
      label: "Participant Consent",
      field: "participantConsent",
      sort: "asc",
    },
    {
      label: "HID Number",
      field: "hidNumber",
      sort: "asc",
    },
    // {
    //   label: "Block Name",
    //   field: "blockName",
    //   sort: "asc",
    // },
    {
      label: "Sector",
      field: "sector",
      sort: "asc",
    },
    {
      label: "Pid",
      field: "pid",
      sort: "asc",
    },
    {
      label: "City",
      field: "city",
      sort: "asc",
    },
    // {
    //   label: "Neighbourhood",
    //   field: "neighbourhood",
    //   sort: "asc",
    // },
    // {
    //   label: "HouseHold Number",
    //   field: "householdNumber",
    //   sort: "asc",
    // },
    {
      label: "HouseHold Members",
      field: "householdMembers",
      sort: "asc",
    },
    {
      label: "CHC Name",
      field: "chcName",
      sort: "asc",
    },
    {
      label: "PHC Name",
      field: "phcName",
      sort: "asc",
    },
    {
      label: "Health Center Name",
      field: "healthCenterName",
      sort: "asc",
    },
    {
      label: "Date",
      field: "date",
      sort: "asc",
    },
    {
      label: "Symptoms",
      field: "symptoms",
      sort: "asc",
    },
    {
      label: "Symptoms Members",
      field: "symptomsMembers",
      sort: "asc",
    },
    {
      label: "PatientName",
      field: "patientName",
      sort: "asc",
    },
    {
      label: "Patient Age",
      field: "patientAge",
      sort: "asc",
    },
    {
      label: "Gender",
      field: "gender",
      sort: "asc",
    },
    {
      label: "MaritalStatus",
      field: "maritalStatus",
      sort: "asc",
    },
    {
      label: "EducationStatus",
      field: "educationalStatus",
      sort: "asc",
    },
    {
      label: "OccupationStatus",
      field: "occupationStatus",
      sort: "asc",
    },
    {
      label: "TobaccoStatus",
      field: "tobaccoStatus",
      sort: "asc",
    },
    {
      label: "IsDrinkingAlcohol",
      field: "isDrinkingAlcohol",
      sort: "asc",
    },
    {
      label: "PatientWaistInCm",
      field: "patientWaistInCm",
      sort: "asc",
    },
    {
      label: "PatientHeightInCm",
      field: "patientHeightInCm",
      sort: "asc",
    },
    {
      label: "isFever",
      field: "isFever",
      sort: "asc",
    },
    {
      label: "feverDays",
      field: "feverDays",
      sort: "asc",
    },
    {
      label: "isMyalgia",
      field: "isMyalgia",
      sort: "asc",
    },
    {
      label: "myalgiaDays",
      field: "myalgiaDays",
      sort: "asc",
    },
    {
      label: "coughDays",
      field: "coughDays",
      sort: "asc",
    },
    {
      label: "isShortnessBreath",
      field: "isShortnessBreath",
      sort: "asc",
    },
    {
      label: "shortnessBreathDays",
      field: "shortnessBreathDays",
      sort: "asc",
    },
    {
      label: "isFatigue",
      field: "isFatigue",
      sort: "asc",
    },
    {
      label: "fatigueDays",
      field: "fatigueDays",
      sort: "asc",
    },
    {
      label: "isSoreThroat",
      field: "isSoreThroat",
      sort: "asc",
    },
    {
      label: "soreThroatDays",
      field: "soreThroatDays",
      sort: "asc",
    },
    {
      label: "isRunnyNose",
      field: "isRunnyNose",
      sort: "asc",
    },
    {
      label: "runnyNoseDays",
      field: "runnyNoseDays",
      sort: "asc",
    },
    {
      label: "isMusclePains",
      field: "isMusclePains",
      sort: "asc",
    },
    {
      label: "musclePainDays",
      field: "musclePainDays",
      sort: "asc",
    },
    {
      label: "isHeadache",
      field: "isHeadache",
      sort: "asc",
    },
    {
      label: "headacheDays",
      field: "headacheDays",
      sort: "asc",
    },
    {
      label: "isVomiting",
      field: "isVomiting",
      sort: "asc",
    },
    {
      label: "vomitingDays",
      field: "vomitingDays",
      sort: "asc",
    },
    {
      label: "isConsultPractitioner",
      field: "isConsultPractitioner",
      sort: "asc",
    },
    {
      label: "facilityName",
      field: "facilityName",
      sort: "asc",
    },
    {
      label: "consultantTypes",
      field: "consultantTypes",
      sort: "asc",
    },
    {
      label: "isHospitalised",
      field: "isHospitalised",
      sort: "asc",
    },
    {
      label: "hospitalisedDays",
      field: "hospitalisedDays",
      sort: "asc",
    },
    {
      label: "isTakeMedicine",
      field: "isTakeMedicine",
      sort: "asc",
    },
    {
      label: "drugTypes",
      field: "drugTypes",
      sort: "asc",
    },
    {
      label: "isCovidTest",
      field: "isCovidTest",
      sort: "asc",
    },
    {
      label: "covidResult",
      field: "covidResult",
      sort: "asc",
    },
    {
      label: "isVaccinated",
      field: "isVaccinated",
      sort: "asc",
    },
    {
      label: "noOfDoses",
      field: "noOfDoses",
      sort: "asc",
    },
    {
      label: "preExistingSymptoms",
      field: "preExistingSymptoms",
      sort: "asc",
    },
    {
      label: "isPregnant",
      field: "isPregnant",
      sort: "asc",
    },
    {
      label: "trimesterValue",
      field: "trimesterValue",
      sort: "asc",
    },
    {
      label: "isWearingMask",
      field: "isWearingMask",
      sort: "asc",
    },
    {
      label: "isSanitizingHands",
      field: "isSanitizingHands",
      sort: "asc",
    },
    {
      label: "isTravelAnywhere",
      field: "isTravelAnywhere",
      sort: "asc",
    },
    {
      label: "isDayOff",
      field: "isDayOff",
      sort: "asc",
    },
    {
      label: "dayOffDays",
      field: "dayOffDays",
      sort: "asc",
    },
    {
      label: "isIncomeAffected",
      field: "isIncomeAffected",
      sort: "asc",
    },
    {
      label: "affectedValue",
      field: "affectedValue",
      sort: "asc",
    },
    {
      label: "isTakeSamples",
      field: "isTakeSamples",
      sort: "asc",
    },
    {
      label: "sampleRefusalreason",
      field: "sampleRefusalreason",
      sort: "asc",
    },
    {
      label: "swabId",
      field: "swabId",
      sort: "asc",
    },
    {
      label: "swabDate",
      field: "swabDate",
      sort: "asc",
    },
  ];

  // Define rows
  const rows = formData?.map((formDataItem, index) => ({
    userId: formDataItem.HW_ID || formDataItem.HW_ID,
    interviewerName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .interviewerName === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .interviewerName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
              .interviewerName
        : filler,
    interviewerId:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .interviewerId === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .interviewerId === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId
        : filler,

    district:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
            "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
            null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName
        : filler,
    participantConsent:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .participantConsent === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .participantConsent === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
              .participantConsent
        : filler,
    hidNumber:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
            "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
            null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber
        : filler,

    sector:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
            "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
            null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName
        : filler,

    pid:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).pid
        : filler,

    city:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).city === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).city === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).city
        : filler,
    householdMembers:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .householdMembers === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .householdMembers === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
              .householdMembers
        : filler,

    chcName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName ===
            "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName
        : filler,

    phcName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName ===
            "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName
        : filler,

    healthCenterName:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .healthCenterName === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .healthCenterName === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
              .healthCenterName
        : filler,

    date:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).date === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).date === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).date
        : filler,

    symptoms:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms ===
            "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms ===
            null ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)?.symptoms
            .length === 0
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms.join(
              ","
            )
        : filler,

    symptomsMembers:
      formDataItem.FORM_DATA.generalInformation !== undefined
        ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .symptomsMembers === "" ||
          JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .symptomsMembers === null
          ? filler2
          : JSON.parse(formDataItem.FORM_DATA.generalInformation)
              .symptomsMembers
        : filler,

    patientName: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientName",
      ","
    ),
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientName === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientName
    //   : filler,
    patientAge: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientAge",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientAge === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientAge
    //   : filler,

    gender: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "gender",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).gender === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).gender
    //   : filler,

    maritalStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "maritalStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).maritalStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).maritalStatus
    //   : filler,

    educationalStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "educationStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).educationalStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).educationalStatus
    //   : filler,

    occupationStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "occupationStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).occupationStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).occupationStatus
    //   : filler,

    tobaccoStatus: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "tobaccoStatus",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).tobaccoStatus === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).tobaccoStatus
    //   : filler,

    isDrinkingAlcohol: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "isDrinkingAlcohol",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).isDrinkingAlcohol === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).isDrinkingAlcohol
    //   : filler,

    patientWaistInCm: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientWaistInCm",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientWaistInCm === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientWaistInCm
    //   : filler,
    patientHeightInCm: myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientHeightInCm",
      ","
    ),

    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(
    //       formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //     ).patientHeightInCm === ("" || null)
    //     ? filler2
    //     : JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientHeightInCm
    //   : filler,

    // patientWeightInKg:
    //   formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    //   formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    //   formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //     ? JSON.parse(
    //         formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //       ).patientWeightInKg === ("" || null)
    //       ? filler2
    //       : JSON.parse(
    //           formDataItem.FORM_DATA.houseHoldMemberModel[0].houseMembersHealth
    //         ).patientWeightInKg
    //     : filler,

    // isFever:
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.houseHoldMemberModel[0].currentHealth).isFever ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.houseHoldMemberModel[0].currentHealth).isFever
    //   : filler,
    isFever: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isFever",
      ","
    ),

    feverDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "feverDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).feverDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).feverDays
    //   : filler,

    isMyalgia: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isMyalgia",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isMyalgia ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isMyalgia
    //   : filler,

    myalgiaDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "myalgiaDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).myalgiaDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).myalgiaDays
    //   : filler,

    isCough: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isCough",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isCough ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isCough
    //   : filler,

    coughDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "coughDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).coughDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).coughDays
    //   : filler,

    isShortnessBreath: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isShortnessBreath",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isShortnessBreath ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isShortnessBreath
    //   : filler,

    shortnessBreathDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "shortnessBreathDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .shortnessBreathDays === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).shortnessBreathDays
    //   : filler,

    isShortnessBreathDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isShortnessBreathDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .isShortnessBreathDays === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //         .isShortnessBreathDays
    //   : filler,

    isFatigue: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isFatigue",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isFatigue ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isFatigue
    //   : filler,

    fatigueDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "fatigueDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).fatigueDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).fatigueDays
    //   : filler,

    isSoreThroat: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isSoreThroat",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isSoreThroat ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isSoreThroat
    //   : filler,

    soreThroatDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "soreThroatDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).soreThroatDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).soreThroatDays
    //   : filler,

    isRunnyNose: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isRunnyNose",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isRunnyNose ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isRunnyNose
    //   : filler,

    runnyNoseDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "runnyNoseDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).runnyNoseDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).runnyNoseDays
    //   : filler,

    isMusclePains: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isMusclePains",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isMusclePains ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isMusclePains
    //   : filler,

    musclePainDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "musclePainDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).musclePainDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).musclePainDays
    //   : filler,

    isHeadache: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isHeadache",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isHeadache ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isHeadache
    //   : filler,

    headacheDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "headacheDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).headacheDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).headacheDays
    //   : filler,

    isVomiting: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isVomiting",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isVomiting ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isVomiting
    //   : filler,

    vomitingDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "vomitingDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).vomitingDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).vomitingDays
    //   : filler,
    isConsultPractitioner: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isConsultPractitioner",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .isConsultPractitioner === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //         .isConsultPractitioner
    //   : filler,

    facilityName: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "facilityName",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).facilityName ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).facilityName
    //   : filler,

    consultantTypes: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "consultantTypes",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).consultantTypes ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).consultantTypes
    //   : filler,

    isHospitalised: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isHospitalised",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isHospitalised ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isHospitalised
    //   : filler,

    hospitalisedDays: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "hospitalisedDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).hospitalisedDays ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).hospitalisedDays
    //   : filler,

    isTakeMedicine: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isTakeMedicine",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeMedicine ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeMedicine
    //   : filler,

    drugTypes: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "drugTypes",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).drugTypes ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).drugTypes
    //   : filler,

    isCovidTest: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isCovidTest",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isCovidTest ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isCovidTest
    //   : filler,

    covidResult: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "covidResult",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).covidResult ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).covidResult
    //   : filler,

    isVaccinated: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isVaccinated",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isVaccinated ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isVaccinated
    //   : filler,

    noOfDoses: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "noOfDoses",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).noOfDoses ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).noOfDoses
    //   : filler,

    preExistingSymptoms: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "preExistingSymptoms",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .preExistingSymptoms === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).preExistingSymptoms
    //   : filler,

    isPregnant: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isPregnant",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== null &&
    // formDataItem.FORM_DATA.houseHoldMemberModel !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isPregnant ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isPregnant
    //   : filler,
    trimesterValue: myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "trimesterValue",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).trimesterValue ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).trimesterValue
    //   : filler,

    isWearingMask: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isWearingMask",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isWearingMask ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isWearingMask
    //   : filler,

    isSanitizingHands: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isSanitizingHands",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isSanitizingHands ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isSanitizingHands
    //   : filler,

    isTravelAnywhere: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isTravelAnywhere",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isTravelAnywhere ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isTravelAnywhere
    //   : filler,

    isDayOff: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isDayOff",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isDayOff ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isDayOff
    //   : filler,

    dayOffDays: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "dayOffDays",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).dayOffDays ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).dayOffDays
    //   : filler,

    isIncomeAffected: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isIncomeAffected",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isIncomeAffected ===
    //     ""
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isIncomeAffected
    //   : filler,

    affectedValue: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "affectedValue",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).affectedValue ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).affectedValue
    //   : filler,

    isTakeSamples: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isTakeSamples",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeSamples ===
    //     ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).isTakeSamples
    //   : filler,

    sampleRefusalreason: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "sampleRefusalreason",
      ","
    ),
    // formDataItem.FORM_DATA.currentHealth !== null &&
    // formDataItem.FORM_DATA.currentHealth !== undefined &&
    // formDataItem.FORM_DATA.houseHoldMemberModel[0] !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.currentHealth)
    //       .sampleRefusalreason === ("" || null)
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.currentHealth).sampleRefusalreason
    //   : filler,

    swabId: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "swabId",
      ","
    ),

    swabDate: myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "swabDate",
      ","
    ),
  }));

  const downloadrows = formData.map((formDataItem, index) => [
    formDataItem.HW_ID || formDataItem.HW_ID,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .interviewerName === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .interviewerName === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerName
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId ===
          "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId ===
          null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).interviewerId
      : filler,
    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
          "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName ===
          null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).districtName
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .participantConsent === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .participantConsent === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation)
            .participantConsent
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
          "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber ===
          null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).hhidNumber
      : filler,

    // formDataItem.FORM_DATA.generalInformation !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.generalInformation).blockName ===
    //       "" ||
    //     JSON.parse(formDataItem.FORM_DATA.generalInformation).blockName === null
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.generalInformation).blockName
    //   : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
          "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName ===
          null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).sectorName
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).pid === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).pid
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).city === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).city === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).city
      : filler,

    // formDataItem.FORM_DATA.generalInformation !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
    //       .neighbourhoodName === "" ||
    //     JSON.parse(formDataItem.FORM_DATA.generalInformation)
    //       .neighbourhoodName === null
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.generalInformation)
    //         .neighbourhoodName
    //   : filler,

    // formDataItem.FORM_DATA.generalInformation !== undefined
    //   ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
    //       .householdNumber === "" ||
    //     JSON.parse(formDataItem.FORM_DATA.generalInformation)
    //       .householdNumber === null
    //     ? filler2
    //     : JSON.parse(formDataItem.FORM_DATA.generalInformation).householdNumber
    //   : filler,

    formDataItem?.FORM_DATA?.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          ?.householdMembers === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation)
          ?.householdMembers === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation)
            ?.householdMembers
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).chcName
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).phcName
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .healthCenterName === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .healthCenterName === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).healthCenterName
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).date === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).date === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).date
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms ===
          null ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms
          .length === 0
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).symptoms.join(
            ", "
          )
      : filler,

    formDataItem.FORM_DATA.generalInformation !== undefined
      ? JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .symptomsMembers === "" ||
        JSON.parse(formDataItem.FORM_DATA.generalInformation)
          .symptomsMembers === null
        ? filler2
        : JSON.parse(formDataItem.FORM_DATA.generalInformation).symptomsMembers
      : filler,

    myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "patientName"),
    myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "patientAge"),
    myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "gender"),
    myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "maritalStatus"),
    myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "educationStatus"),
    myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "occupationStatus"
    ),
    myFunction(formDataItem.FORM_DATA, "houseMembersHealth", "tobaccoStatus"),
    myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "isDrinkingAlcohol"
    ),
    myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientWaistInCm"
    ),
    myFunction(
      formDataItem.FORM_DATA,
      "houseMembersHealth",
      "patientHeightInCm"
    ),

    myFunction(formDataItem.FORM_DATA, "currentHealth", "isFever"),

    myFunction(formDataItem.FORM_DATA, "currentHealth", "feverDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isMyalgia"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "myalgiaDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isCough"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "coughDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isShortnessBreath"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "shortnessBreathDays"),
    myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isShortnessBreathDays"
    ),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isFatigue"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "fatigueDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isSoreThroat"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "soreThroatDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isRunnyNose"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "runnyNoseDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isMusclePains"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "musclePainDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isHeadache"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "headacheDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isVomiting"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "vomitingDays"),
    myFunction(
      formDataItem.FORM_DATA,
      "currentHealth",
      "isConsultPractitioner"
    ),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "facilityName"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "consultantTypes"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isHospitalised"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "hospitalisedDays"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isTakeMedicine"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "drugTypes"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isCovidTest"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "covidResult"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isVaccinated"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "noOfDoses"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "preExistingSymptoms"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "isPregnant"),
    myFunction(formDataItem.FORM_DATA, "currentHealth", "trimesterValue"),
    myFunction(formDataItem.FORM_DATA, "generalRespondents", "isWearingMask"),
    myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isSanitizingHands"
    ),
    myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isTravelAnywhere"
    ),
    myFunction(formDataItem.FORM_DATA, "generalRespondents", "isDayOff"),
    myFunction(formDataItem.FORM_DATA, "generalRespondents", "dayOffDays"),
    myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "isIncomeAffected"
    ),
    myFunction(formDataItem.FORM_DATA, "generalRespondents", "affectedValue"),
    myFunction(formDataItem.FORM_DATA, "generalRespondents", "isTakeSamples"),

    myFunction(
      formDataItem.FORM_DATA,
      "generalRespondents",
      "sampleRefusalreason"
    ),

    myFunction(formDataItem.FORM_DATA, "generalRespondents", "swabId"),
    myFunction(formDataItem.FORM_DATA, "generalRespondents", "swabDate"),
  ]);

  const labelNames = [
    "User ID",
    "Interviewer Name",
    "Interviewer ID",
    "District",
    "Participant Consent",
    "HID Number",
    // "Block Name",
    "Sector",
    "Pid",
    "City",
    // "Neighbourhood",
    // "HouseHold Number",
    "HouseHold Members",
    "CHC Name",
    "PHC Name",
    "Health Center Name",
    "Date",
    "Symptoms",
    "Symptoms Members",
    "PatientName",
    "Patient Age",
    "Gender",
    "MaritalStatus",
    "EducationStatus",
    "OccupationStatus",
    "TobaccoStatus",
    "IsDrinkingAlcohol",
    "PatientWaistInCm",
    "PatientHeightInCm",
    "isFever",
    "feverDays",
    "isMyalgia",
    "myalgiaDays",
    "coughDays",
    "isShortnessBreath",
    "shortnessBreathDays",
    "isFatigue",
    "fatigueDays",
    "isSoreThroat",
    "soreThroatDays",
    "isRunnyNose",
    "runnyNoseDays",
    "isMusclePains",
    "musclePainDays",
    "isHeadache",
    "headacheDays",
    "isVomiting",
    "vomitingDays",
    "isConsultPractitioner",
    "facilityName",
    "consultantTypes",
    "isHospitalised",
    "hospitalisedDays",
    "isTakeMedicine",
    "drugTypes",
    "isCovidTest",
    "covidResult",
    "isVaccinated",
    "noOfDoses",
    "preExistingSymptoms",
    "isPregnant",
    "trimesterValue",
    "isWearingMask",
    "isSanitizingHands",
    "isTravelAnywhere",
    "isDayOff",
    "dayOffDays",
    "isIncomeAffected",
    "affectedValue",
    "isTakeSamples",
    "sampleRefusalreason",
    "swabId",
    "swabDate",
  ];

  const csvData = [labelNames, ...downloadrows];

  const datapasscsv = [
    {
      userID: 1,
      mobile: "9876543210",
      generalInformation: {
        interviewerName: "Sachin Tendulkar",
        interviewerId: "123",
      },
      houseHoldMemberModel: [
        {
          houseMembersHealth: { patientName: "Madhu", patientAge: 22 },
          currentHealth: { isFever: 1, feverDays: 1, isMyalgia: 1 },
          generalRespondents: { isWearingMask: 2, isSanitizingHands: 2 },
        },
        {
          houseMembersHealth: { patientName: "raju", patientAge: 22 },
          currentHealth: { isFever: 2, feverDays: 6, isMyalgia: 4 },
          generalRespondents: { isWearingMask: 1, isSanitizingHands: 9 },
        },
      ],
    },
    {
      userID: 2,
      mobile: "9876543210",
      generalInformation: {
        interviewerName: "Sachin Tendulkar",
        interviewerId: "123",
      },
      houseHoldMemberModel: [
        {
          houseMembersHealth: { patientName: "rani", patientAge: 22 },
          currentHealth: {
            isFever: "null",
            feverDays: "null",
            isMyalgia: "null",
          },
          generalRespondents: { isWearingMask: 8, isSanitizingHands: 7 },
        },
      ],
    },
    {
      userID: 1,
      mobile: "9876543210",
      generalInformation: {
        interviewerName: "Sachin Tendulkar",
        interviewerId: "123",
      },
      houseHoldMemberModel: [
        {
          houseMembersHealth: { patientName: "Madhu", patientAge: 22 },
          currentHealth: { isFever: 77, feverDays: 55, isMyalgia: 85 },
          generalRespondents: { isWearingMask: 88, isSanitizingHands: 88 },
        },
        {
          houseMembersHealth: { patientName: "raju", patientAge: 22 },
          currentHealth: { isFever: 87, feverDays: 8, isMyalgia: 88 },
          generalRespondents: { isWearingMask: 33, isSanitizingHands: 77 },
        },
      ],
    },
  ];

  return (
    <div>
      <div style={{ display: "flex" }} className="testpagemain">
        <h3 className="pageheading">Household Form Data </h3>
        <div className="testpage">
          <label htmlFor="startDate" style={{ margin: 0 }}>
            Start Date:{" "}
          </label>
          <input
            type="date"
            id="startDate"
            value={startDate ? startDate.toISOString().split("T")[0] : ""}
            onChange={(e) => setStartDate(new Date(e.target.value))}
          />
          <label
            htmlFor="endDate"
            style={{ marginLeft: "20px", marginBottom: 0 }}
          >
            End Date:
          </label>
          <input
            type="date"
            id="endDate"
            value={endDate ? endDate.toISOString().split("T")[0] : ""}
            onChange={(e) => setEndDate(new Date(e.target.value))}
          />
          <button
            style={{ marginLeft: "20px" }}
            onClick={handleFilterByDateRange}
            disabled={isApplyButtonDisabled}
          >
            Apply Date Range
          </button>
        </div>
      </div>

      {/* <DownloadTableExcel
        filename="users table"
        sheet="users"
        currentTableRef={tableRef.current}
      /> */}
      <MDBDataTable
        className="table-container"
        striped
        bordered
        small
        caption="Household Data"
        data={{ columns, rows }}
        // exportToCSV={download}
        noBottomColumns={true}
      />
      {/* <CSVLink
        data={csvData}
        filename={"household-data.csv"}
        className="btn btn-primary mb-4"
      >
        Export to CSV
      </CSVLink> */}
      {console.log(formData,".......")}
      {download && <DownloadCSVButton data={formData} date={`${endDate} - ${startDate}`} startingDate={startDate} endingDate={endDate}/>}
    </div>
  );
};

export default Test;
