import React from "react";
import "./App.css";
import {  Route, Switch} from "react-router-dom";
import RoutesWithNavigation from "./components/RoutesWithNavigation";
import Login from "./components/pages/Login";
import HomePage from "./Landing";


const App = () => {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/pages/login" exact component={Login} />
      <RoutesWithNavigation />
    </Switch>
  );
};

export default App;
