import React from "react";
import ReactECharts from "echarts-for-react";

const GraphDynamicComponent = ({ keys, FirstValue,FirstKey, SecondValue,SecondKey }) => {
    // Calculate percentage values
    const percentageValues = FirstValue && FirstValue.map((val, index) => {
        const secondVal = SecondValue[index];
        return secondVal !== 0 ? parseFloat(((secondVal / val) * 100).toFixed(2)) : 0;
    });

    const chartData = {
        xAxis: {
            type: "category",
            data: keys,
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: `${FirstKey}`,
                type: "bar",
                data: FirstValue,
            },
            {
                name: `${SecondKey}`,
                type: "bar",
                data: SecondValue,
                label: {
                    show: true,
                    position: 'top',
                    formatter: (params) => {
                        const index = params.dataIndex;
                        // return `${params.value} (${percentageValues[index]}%)`;
                        return `${percentageValues[index]}%`
                    },
                },
            },
        ],
    };

    return (
        <ReactECharts
            option={{
                xAxis: chartData.xAxis,
                yAxis: chartData.yAxis,
                series: chartData.series,
                tooltip: {
                    trigger: "axis",
                    formatter: (params) => {
                        let tooltipContent = params[0].axisValue + "<br/>";
                        params.forEach((param) => {
                            tooltipContent += `${param.seriesName}: ${param.value}<br/>`;
                        });
                        // Add percentage information to the tooltip
                        tooltipContent += `Percentage: ${percentageValues[params[0].dataIndex]}%<br/>`;
                        return tooltipContent;
                    },
                },
            }}
            style={{ height: "400px" }}
        />
    );
};

export default GraphDynamicComponent;
