import React, { useEffect } from "react";
import Lottie from "lottie-web";
import Axios from "axios";
import "./Landing.css";
import "react-toastify/dist/ReactToastify.css";
import {
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBBtn,
  MDBCardHeader,
} from "mdbreact";
import logo from "./images/phfilogo.png";
import clinallylogo from "./images/clinally_logo.png";
import animationData from "./images/home.json";
import { useHistory } from "react-router-dom";
import config from "./config";

const Landing = () => {
  const navigate = useHistory();
  useEffect(() => {
    const animationContainer = document.getElementById("lottie-container");

    if (animationContainer) {
      const animation = Lottie.loadAnimation({
        container: animationContainer,
        animationData: animationData,
        loop: true,
        renderer: "svg",
      });

      return () => {
        animation.destroy();
      };
    }
  }, []);
  const handleLogin = async (e) => {
    console.log("cominf");
    var username = document.getElementById("name");
    var password = document.getElementById("password");

    if (username.value === "" || password.value === "") {
      e.preventDefault();
      e.stopPropagation();
      alert("Please enter both Mobile Number and PIN to login.");
    } else {
      if (username.value !== null && password.value != null) {
        const params = {
          MOBILE: username.value,
          PASSWORD: password.value,
        };
        // console.log(params)

        await Axios.post(`${config.baseUrl}/signin`, params, {
          "content-type": "application/json",
          Accept: "application/json",
        })
          .then((response) => {
            console.log(response);

            if (response.status === 200) {
              // alert("Login successful!");
              console.log(response.data.userdata[0], "userresponse");

              sessionStorage.setItem(
                "LoginUser",
                JSON.stringify(response.data.userdata[0])
              );
              navigate.push("/dashboard");
            } else if (response.status === 422) {
              alert(`${response.data.message}`);
            } else if (response.status === 402) {
              alert("Authentication failed!.Please enter the correct password");
            } else {
              alert(`${response.data.message}`);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert("Please enter valid credentials");
      }
    }
  };

  return (
    <div className="landing-section">
      <div className="landing-inner-section">
        <div className="landing-heading">
          <img src={logo} alt="Logo" className="landing-logo" />
        </div>
        <h1 className="h1-responsive font-weight-bold wow fadeInLeft">
          INCENTIVE PULSE DASHBOARD
        </h1>
        <div className="logo-container">
          <img src={clinallylogo} alt="Logo" className="clinally-logo" />
        </div>
      </div>

      {/* <div className="inner-div">
        <h1 className="h1-responsive font-weight-bold wow fadeInLeft">
          INCENTIVE PULSE DASHBOARD
        </h1>
      </div> */}
      <div>
        <div className="dashboard">
          <div className="dashboard-item dashboard-structure">
            <div
              id="lottie-container"
              className="inner-dashboard-structure"
            ></div>
          </div>
          <div className="dashboard-item structure-section">
            <MDBCard className="loginCard">
              <MDBCardHeader className="textCenter">Login</MDBCardHeader>
              <MDBCardBody className="mx-3" style={{ marginTop: "5%" }}>
                <form className="mx-3">
                  <MDBInput
                    id="name"
                    placeholder="  Mobile Number"
                    icon="mobile-alt"
                    group
                    type="text"
                    validate
                    pattern="^[0-9]{10}$"
                    maxLength="10"
                    border="none"
                    errorMessage="Please enter a valid 10-digit mobile number"
                    className="landing-card"
                    inputStyle={{
                      marginTop: "1rem",
                      padding: "0.5rem",
                    }}
                  />
                  <MDBInput
                    id="password"
                    placeholder="  Password"
                    icon="key"
                    group
                    type="password"
                    validate
                    className="password-input"
                    inputStyle={{
                      paddingTop: "1rem",
                      paddingBottom: "0.5rem",
                      border: "none",
                    }}
                  />
                  <div className="login-container">
                    <MDBBtn
                      className="loginBtn"
                      color="danger" // Set the desired color (e.g., "primary", "secondary", "danger", etc.)
                      onClick={handleLogin}
                    >
                      LOGIN
                    </MDBBtn>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
