import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import DashboardV1 from "./v1";
import DashboardUserCreat from "./DashboardUserCreat";
import "./V2Styles.css";

const DashboardV2 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const redirectToDashboardUserCreat = () => {
    history.push("/DashboardUserCreat");
  };

  const redirectToUserList = () => {
    history.push("/UserList");
  };

  const redirectToHousehold = () => {
    history.push("/HouseHoldFormData");
  };

  return (
    <Router>
      {/* <MDBContainer fluid>
        <MDBRow>
          <main >
       
            <Switch>
              <Route path="/DashboardUserCreat">
                <MDBModal isOpen={isModalOpen} toggle={toggleModal}>
                  <MDBModalHeader toggle={toggleModal}>Add User</MDBModalHeader>
                  <MDBModalBody>
                    <DashboardUserCreat />
                  </MDBModalBody>
                </MDBModal>
              </Route>
              
            </Switch>
          </main>
        </MDBRow>
      </MDBContainer> */}

      <DashboardV1 />
    </Router>
  );
};

export default DashboardV2;
