import React, { Component } from "react";
import "../App.css";
// import SideNavigation from "./SideNavigation";
import TopNavigation from "./TopNavigation";
import Copyrights from "./Footer";
import Routes from "./Routes";
import Sidenav from "./dashboard/sidenav";
import { MDBCol } from "mdbreact";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: "",
      sideNavToggled: false,
      breakWidth: 1400,
    };
  }

  componentDidUpdate(prevProps, nextProps, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.assessLocation(this.props.location.pathname);
    }
  }
  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.assessLocation(this.props.location.pathname);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    });
  };

  toggleSideNav = () => {
    this.setState({
      sideNavToggled: !this.state.sideNavToggled,
    });
  };

  assessLocation = (location) => {
    let locationString;
    this.setState({
      currentPage: locationString,
    });
  };
  mytoggle = (value) => {
    this.setState({
      toggle: value,
    });
  };
  render() {
    const dynamicLeftPadding = {
      paddingLeft: 0,
    };

    return (
      <div className="app">
        <div className="flexible-content white-skin">
          <div className="SideNavBarDashboard">
            {console.log(
              this.state.sideNavToggled,
              "this.state.sideNavToggled"
            )}
            {/* <Sidenav
            
              breakWidth={this.state.breakWidth}
              style={{ transition: "all .3s", width: "0" }}
              triggerOpening={this.state.sideNavToggled}
              onLinkClick={() => this.toggleSideNav()}
              // isSlim={}
            /> */}
            <Sidenav
              breakWidth={this.state.breakWidth}
              isModalOpen={this.state.sideNavToggled} // Pass isModalOpen down as prop
              setIsModalOpen={this.toggleSideNav} // Pass toggleSideNav as prop
            />
          </div>

          <div className="MainDashboardContent">
            <TopNavigation
              toggle={this.state.sideNavToggled}
              onSideNavToggleClick={this.toggleSideNav}
              routeName={this.state.currentPage}
              className="white-skin"
            />
            <main style={{ ...dynamicLeftPadding, margin: "6rem 0rem 3rem" }}>
              <Routes onChange={() => this.assessLocation()} />
            </main>
            <Copyrights
              style={{
                ...dynamicLeftPadding,
                position: "fixed",
                width: "100%",
              }}
              className="d-none d-lg-block"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
