import React, { useEffect, useState } from "react";
import Axios from "axios";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBSpinner } from "mdbreact";
import config from "../../config";
const Login = () => {
  const [listOfHealthWorkersList, setListOfHealthWorkers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsersCount();
  }, []);

  async function getUsersCount() {
    Axios.get(`${config.baseUrl}/getHandleUsersCountClick`)
      .then((res) => {
        console.log("API Response:", res);
        setListOfHealthWorkers(res.data.recordset);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user count:", error);
      });
  }

  return (
    <div>
      {loading ? (
        <div className="text-center mt-5">
          <MDBSpinner small />
        </div>
      ) : (
        <>
          <MDBCard style={{ width: "50%", height: "100%", marginLeft: "20%" }}>
            <h2 className="card-title"></h2>
            <MDBCardHeader>LIST OF HEALTH CENTRES</MDBCardHeader>
            <MDBCardBody>
              {listOfHealthWorkersList.map((healthWorker, index) => (
                <div key={index}>
                  <p>{healthWorker.HC_FACILITY_NAME}</p>
                </div>
              ))}
            </MDBCardBody>
          </MDBCard>
        </>
      )}
    </div>
  );
};

export default Login;
