import React, { useState } from "react";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdbreact";
import { MDBSideNav, MDBSideNavNav, MDBSideNavLink, MDBIcon } from "mdbreact";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "./UserCreate.css";
import DashboardV2 from "./v2";
import config from "../../config";
// const GenderButtons = () => {
//   const [selectedGender, setSelectedGender] = useState;(null)
// };

const DashboardUserCreat = ({ onUserAdded }) => {
  const [selectGender, setSelectGender] = useState([]);
  const history = useHistory();
  const genderOptions = [
    { text: "Male", value: "male" },
    { text: "Female", value: "female" },
  ];

  const [error, setError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [data, setData] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    gender: "",
    city: "",
    state: "",
    region: "",
    role: "",
    password: "1234",
  });

  const onChangeA = (e, ctrlName, ctrlValue) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [ctrlName]: ctrlValue,
    }));
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleMobileChange = (e) => {
    const regex = /^[0-9]{10}$/;
    if (!regex.test(e.target.value)) {
      setMobileError("* Enter Valid Mobile No");
    } else {
      setMobileError("");
    }
    onChangeA(e, "phonenumber", e.target.value);
  };
  const handleEmailChange = (e) => {
    if (!isValidEmail(e.target.value)) {
      setError("Invalid Email");
    } else {
      setError("");
    }
    onChangeA(e, "email", e.target.value);
  };

  const addUser = (e) => {
    e.preventDefault();

    if (
      formValues.firstname !== "" &&
      formValues.lastname !== "" &&
      formValues.email !== "" &&
      formValues.phonenumber !== "" &&
      formValues.gender !== "" &&
      formValues.city !== "" &&
      formValues.state !== "" &&
      formValues.region !== "" &&
      formValues.role !== ""
    ) {
      const payload = {
        FIRST_NAME: formValues.firstname,
        LAST_NAME: formValues.lastname,
        MOBILE: formValues.phonenumber,
        EMAIL: formValues.email,
        GENDER: formValues.gender,
        CITY: formValues.city,
        STATE: formValues.state,
        REGION: formValues.region,
        PASSWORD: formValues.password,
        ROLE: formValues.role,
        Status:0,
      };

      console.log("Form Values:", formValues);
      console.log("Payload:", payload);

      Axios.post(`${config.baseUrl}/addUser`, payload, {
        "content-type": "application/json",
        Accept: "application/json",
      })
        .then((response) => {
          console.log("Response:", response);

          if (response?.status === 200) {
            setData([...data, payload]);
            setShowSuccessPopup(true);
            toast.success("User Added Successfully");

            // Reset the formValues after successful addition
            setFormValues({
              firstname: "",
              lastname: "",
              email: "",
              phonenumber: "",
              gender: "",
              city: "",
              state: "",
              region: "",
              password: "",
              role: "",
            });

            // If needed, navigate to the userlist page
            // history.push("/userlist");
          } else {
            console.error("Error adding user:", response?.status);
            toast.error("Error adding user. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Error adding user:", error);
          toast.error("Error adding user. Please try again.");
        });
    } else {
      toast.error("Please enter all the fields.", {
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <style>
        {`
        body, html {
          margin: 0;
          padding: 0;
          min-height: 100%;
          background-color: #FFFF;
        }
      `}
      </style>{" "}
      <h3 className="pageheading">User Creation </h3>
      <MDBCard
        style={{
          background: "#F3F3F3",
          // marginTop: "4.3%",
          // marginLeft: "3rem",
          // width: "62rem",
          // height: "33rem",
          borderRadius: "12px",
          // position: "absolute",
          // top: "50%",
          boxShadow: "none",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
        }}
      >
        <MDBCardHeader className="userCreateCardHeader" id="UserCreation">
          <p> User Details</p>
        </MDBCardHeader>
        <MDBCardBody>
          <form className="user-form">
            <div className="user-form-container">
              <div className="user-form-row">
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="firstname">
                    First Name *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="firstname"
                    name="firstname"
                    placeholder="First Name"
                    onChange={(e) => onChangeA(e, "firstname", e.target.value)}
                  />
                </div>

                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="lastname">
                    Last Name *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Last Name"
                    onChange={(e) => onChangeA(e, "lastname", e.target.value)}
                  />
                </div>

                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="email">
                    Email *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleEmailChange}
                  />
                </div>
              </div>

              <div className="user-form-row">
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="mobile">
                    Mobile No *
                  </label>
                  <input
                    className="user-form-input"
                    type="text"
                    id="mobile"
                    name="mobile"
                    placeholder="Phone Number"
                    maxLength={10}
                    onChange={handleMobileChange}
                  />
                </div>

                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="gender">
                    Gender *
                  </label>
                  <select
                    className="user-form-select"
                    id="gender"
                    value={formValues.gender}
                    onChange={(e) => onChangeA(e, "gender", e.target.value)}
                  >
                    <option value="" disabled>
                      Choose Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </select>
                </div>

                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="city">
                    City *
                  </label>
                  <select
                    className="user-form-select"
                    id="city"
                    name="city"
                    onChange={(e) => onChangeA(e, "city", e.target.value)}
                  >
                    <option value="">Select City</option>
                    <option value="Vishakapatnam">Vishakapatnam</option>
                    <option value="Sonipat">Sonipat</option>
                  </select>
                </div>
              </div>

              <div className="user-form-row">
                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="state">
                    State *
                  </label>
                  <select
                    className="user-form-select"
                    id="state"
                    name="state"
                    onChange={(e) => onChangeA(e, "state", e.target.value)}
                  >
                    <option value="">Select State</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Haryana">Haryana</option>
                  </select>
                </div>

                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="region">
                    Region *
                  </label>
                  <select
                    className="user-form-select"
                    id="region"
                    name="region"
                    onChange={(e) => onChangeA(e, "region", e.target.value)}
                  >
                    <option value="">Select Region</option>
                    <option value="urban">Urban</option>
                    <option value="rural">Rural</option>
                  </select>
                </div>

                <div className="user-form-field">
                  <label className="user-form-label" htmlFor="role">
                    Role *
                  </label>
                  <select
                    className="user-form-select"
                    id="role"
                    name="role"
                    onChange={(e) => onChangeA(e, "role", e.target.value)}
                  >
                    <option value="">Select Role</option>
                    <option value="4">Mobile App user</option>
                    <option value="0">Web Admin</option>
                    <option value="10">Vishakapatnam Project SP</option>
                    <option value="20">Sonipat Project SP</option>
                    <option value="11">Vishakapatnam Data SP</option>
                    <option value="21">Sonipat Data SP</option>
                  </select>
                </div>
              </div>

              <button className="user-form-button" onClick={addUser}>
                Add User
              </button>
            </div>

            {/* {showSuccessPopup && (
              <div className="user-form-success-popup">
                User added successfully!
              </div>
            )} */}
          </form>
          {/* </div>  */}

          <ToastContainer />
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};

export default DashboardUserCreat;
