import React, { Component, useEffect } from "react";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBBtnGroup,
  MDBCardHeader,
  MDBCardTitle,
  MDBCardText,
  MDBBadge,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
} from "mdbreact";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { format } from "date-fns";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import maleIcon from "../../images/male-icon.png";
import femaleIcon from "../../images/female-icon.png";
import groupIcon from "../../images/group-icon.png";
import healthcentreIcon from "../../images/healthcentreicon.png";
import GraphComponent from "../dashboard/GraphComponent";
// import PatientsGraphComponent from "./PatientsGraph";
import GoogleMaps from "../dashboard/GoogleMaps";
import "../dashboard/v1.css";
import "boxicons";
import MainCard from "../components/maincard";
import { color } from "echarts";
import config from "../../config";
import SentinnelGraph from "./SentinnelGraph";
import GraphDynamicComponent from "./graphDynamicCompont.js";
import { v4 as uuidv4 } from "uuid";

import GraphsBody from "./graphsBody.js";
const today = new Date();

const DashboardV1 = () => {
  const navigate = useHistory();
  const [loading, setloading] = useState(false); // loading = false;
  const [data, setData] = React.useState({ columns: [], rows: [] });

  const [activeSection, setActiveSection] = useState("Both");
  const [sariCasesOnOxygen, setSariCasesOnOxygen] = useState([]);
  const UserDetails = sessionStorage.getItem("LoginUser");
  const UserDetailsData = JSON.parse(UserDetails);
  const [activeButton, setActiveButton] = useState("");
  const [activeType, setActiveType] = useState("HouseHold");
  const [error, setError] = useState("");
  const [activeArea, setActiveArea] = useState("Both");
  const [participantConsent, setparticipantConsent] = useState([]);
  const [typeOfTime, setTypeOfTime] = useState("monthly");
  const [healthCenterData, setHealthCenterData] = useState({
    opdIliCases: 0,
    sariCasesOnOxygen: 0,
    sariPatientsICU: 0,
    sariDeathsConfirmed: 0,
    sariPatients: 0,
  });

  const [deathPatientModel, setDeathPatientModel] = useState({
    sariMaleCount: 0,
    sariFemaleCount: 0,
  });

  const [generalRespondentData, setGeneralRespondentData] = useState({
    swabId: 0,
    isTakeSamples: 0,
    isInfluenzaAffected: 0,
    influenzaAnswer: 0,
  });

  const [costOfTreatment, setCostOfTreatment] = useState({
    isPneumonia: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [usersData, setUsersData] = useState([]);
  const [Statee, setStatee] = useState({ modal16: false });
  // const [CompletedHouseholdsCount, setCompletedHouseholdsCount] = useState(0);
  const [changeCards, setChangeCards] = useState(true);
  const [ShowModal, setShowModal] = useState(false);
  const [variables, setVariables] = useState({
    usersCount: 0,
    usersCountByCity: [],
    healthCentersCount: 0,
    completedHouseholdsCount: 0,
    totalHouseholdsCount: 0,
    showHealthWorkersList: false,
    selectedHealthWorkers: [],
  });
  const Getusers = (activeSection, activeArea) => {
    Axios.get(`${config.baseUrl}/users/${activeSection}/${activeArea}`).then(
      (response) => {
        //console.log(response, "dsnfks.......");
        setUsersData(response.data);
        setSelectGender(gender);
      }
    );
  };
  useEffect(() => {
    if (UserDetailsData.ROLE !== "0") {
      // getAgeForLILPatients(UserDetailsData.CITY, activeArea);
      setActiveSection(UserDetailsData.CITY);
      Getusers(UserDetailsData.CITY, activeArea);
      getusersCount(UserDetailsData.CITY, activeArea);
      getUserCountByCity();
      getHealthCentersCount();
      getCompletedHouseholdsCount(UserDetailsData.CITY, activeArea);
      getformDataSari(UserDetailsData.CITY);
      getformDataIli(UserDetailsData.CITY, activeArea);
      getformDataPneumonia();
    } else {
      // getAgeForLILPatients(activeSection, activeArea);
      Getusers(activeSection, activeArea);
      getusersCount(activeSection, activeArea);
      getUserCountByCity();
      getHealthCentersCount();
      getCompletedHouseholdsCount(activeSection, activeArea);
      getformDataSari(activeSection);
      getformDataIli(activeSection, activeArea);
      getformDataPneumonia();
    }
  }, [loading]);
  // const getAgeForLILPatients = async () => {
  //   let type = typeOfTime;
  //   try {
  //     const response = await Axios.get(`${config.baseUrl}/householdData`);

  //     if (Array.isArray(response.data.recordset)) {
  //       const formattedData = response.data.recordset

  //         .map((item) => ({
  //           CREATED_DATE: item.CREATED_DATE,
  //           STATUS_DATE: item.STATUS_DATE,
  //           FORM_DATA: item.FORM_DATA
  //             ? JSON.parse(item.FORM_DATA).generalInformation
  //               ? JSON.parse(JSON.parse(item.FORM_DATA).generalInformation)
  //               : null
  //             : null,
  //           houseHoldMemberModel: item.FORM_DATA
  //             ? JSON.parse(item.FORM_DATA).houseHoldMemberModel
  //             : null,
  //         }))
  //         .filter((item) => item.FORM_DATA);

  //       const getDateKey = (dateString) => {
  //         const date = new Date(dateString);
  //         if (type === "monthly") {
  //           const month = date.toLocaleString("default", { month: "long" });
  //           const year = date.getFullYear();
  //           return `${month} ${year}`;
  //         } else if (type === "weekly") {
  //           const week = Math.ceil(date.getDate() / 7);
  //           const month = date.toLocaleString("default", { month: "long" });
  //           const year = date.getFullYear();
  //           return `Week ${week} ${month} ${year}`;
  //         }
  //       };
  //       console.log(formattedData, "formattedData");
  //       const interviewsByDateKey = {};
  //       formattedData.forEach((interview) => {
  //         const dateKey = getDateKey(interview.CREATED_DATE);
  //         if (!interviewsByDateKey[dateKey]) {
  //           interviewsByDateKey[dateKey] = [];
  //         }
  //         interviewsByDateKey[dateKey].push(interview);
  //       });

  //       const participantConsentCountByDateKey = {};
  //       const symptomsMembersCountByDateKey = {};
  //       const patientsCountCountByDateKey = {};
  //       const TotalsymptomsCountCountByDateKey = {};
  //       const countIsTakeSamplesZeroCountByDataKey = {};
  //       const totalcountIsTakeSamplesZeroCountByDataKey = {};
  //       const totalIsInfluenzaAffectedCountByDataKey = {};
  //       const IsInfluenzaAffectedZeroCountByDataKey = {};

  //       for (const dateKey in interviewsByDateKey) {
  //         const interviewsInDateKey = interviewsByDateKey[dateKey];
  //         const consentCount = interviewsInDateKey.filter(
  //           (interview) => interview.FORM_DATA.participantConsent === "Yes"
  //         ).length;
  //         const symptomsCount = interviewsInDateKey.filter(
  //           (interview) =>
  //             interview.FORM_DATA.symptomsMembers &&
  //             interview.FORM_DATA.symptomsMembers > 0
  //         ).length;
  //         const patientsCount = interviewsInDateKey.filter(
  //           (interview) =>
  //             interview.houseHoldMemberModel && interview.houseHoldMemberModel
  //         ).length;

  //         const TotalsymptomsCount = interviewsInDateKey.reduce(
  //           (count, interview) => {
  //             if (
  //               interview.FORM_DATA.symptomsMembers &&
  //               interview.FORM_DATA.symptomsMembers > 0
  //             ) {
  //               // console.log(interview.FORM_DATA.symptomsMembers,"symptomasmembers ..,,.. ,,,")
  //               return count + interview.FORM_DATA.symptomsMembers;
  //             }
  //             return count;
  //           },
  //           0
  //         );

  //         const countIsTakeSamplesZero = interviewsInDateKey.reduce(
  //           (count, interview) => {
  //             const houseHoldMemberModel = interview.houseHoldMemberModel;
  //             if (houseHoldMemberModel) {
  //               houseHoldMemberModel.forEach((member) => {
  //                 const generalRespondents = JSON.parse(
  //                   member.generalRespondents
  //                 );
  //                 if (generalRespondents.isTakeSamples === 0) {
  //                   count++;
  //                 }
  //               });
  //             }
  //             return count;
  //           },
  //           0
  //         );
  //         const totalcountIsTakeSamplesZero = interviewsInDateKey.reduce(
  //           (count, interview) => {
  //             const houseHoldMemberModel = interview.houseHoldMemberModel;
  //             if (houseHoldMemberModel) {
  //               houseHoldMemberModel.forEach((member) => {
  //                 const generalRespondents = JSON.parse(
  //                   member.generalRespondents
  //                 );
  //                 if (
  //                   generalRespondents.isTakeSamples === 0 ||
  //                   generalRespondents.isTakeSamples === 1
  //                 ) {
  //                   count++;
  //                 }
  //               });
  //             }
  //             return count;
  //           },
  //           0
  //         );
  //         const totalIsInfluenzaAffected = interviewsInDateKey.reduce(
  //           (count, interview) => {
  //             const houseHoldMemberModel = interview.houseHoldMemberModel;
  //             if (houseHoldMemberModel) {
  //               houseHoldMemberModel.forEach((member) => {
  //                 const generalRespondents = JSON.parse(
  //                   member.generalRespondents
  //                 );
  //                 if (
  //                   generalRespondents.isInfluenzaAffected === 0 ||
  //                   generalRespondents.isInfluenzaAffected === 1
  //                 ) {
  //                   count++;
  //                 }
  //               });
  //             }
  //             return count;
  //           },
  //           0
  //         );
  //         const IsInfluenzaAffectedZero = interviewsInDateKey.reduce(
  //           (count, interview) => {
  //             const houseHoldMemberModel = interview.houseHoldMemberModel;
  //             if (houseHoldMemberModel) {
  //               houseHoldMemberModel.forEach((member) => {
  //                 const generalRespondents = JSON.parse(
  //                   member.generalRespondents
  //                 );
  //                 if (generalRespondents.isInfluenzaAffected === 0) {
  //                   count++;
  //                 }
  //               });
  //             }
  //             return count;
  //           },
  //           0
  //         );
  //         // const InfluenzaAnswerList = interviewsInDateKey.reduce(
  //         //   (count, interview) => {
  //         //     const houseHoldMemberModel = interview.houseHoldMemberModel;
  //         //     if (houseHoldMemberModel) {
  //         //       houseHoldMemberModel.forEach((member) => {
  //         //         const generalRespondents = JSON.parse(
  //         //           member.generalRespondents
  //         //         );
  //         //         if (generalRespondents.isInfluenzaAffected ) {
  //         //           generalRespondents.isInfluenzaAffected
  //         //         }
  //         //       });
  //         //     }
  //         //     return count;
  //         //   },
  //         //   0
  //         // );
  //         // console.log(countIsTakeSamplesZero,"countIsTakeSamplesZero")

  //         // console.log(symptomsCount, TotalsymptomsCount,"symptommsCount .... .....");

  //         participantConsentCountByDateKey[dateKey] = consentCount;
  //         symptomsMembersCountByDateKey[dateKey] = symptomsCount;
  //         patientsCountCountByDateKey[dateKey] = patientsCount;
  //         TotalsymptomsCountCountByDateKey[dateKey] = TotalsymptomsCount;
  //         countIsTakeSamplesZeroCountByDataKey[dateKey] =
  //           countIsTakeSamplesZero;
  //         totalcountIsTakeSamplesZeroCountByDataKey[dateKey] =
  //           totalcountIsTakeSamplesZero;
  //         totalIsInfluenzaAffectedCountByDataKey[dateKey] =
  //           totalIsInfluenzaAffected;
  //         IsInfluenzaAffectedZeroCountByDataKey[dateKey] =
  //           IsInfluenzaAffectedZero;
  //       }

  //       const dateKeys = Object.keys(participantConsentCountByDateKey);
  //       const participantConsentCounts = Object.values(
  //         participantConsentCountByDateKey
  //       );
  //       const symptomsMembersCounts = Object.values(
  //         symptomsMembersCountByDateKey
  //       );
  //       const patientsCountCount = Object.values(patientsCountCountByDateKey);
  //       const TotalsymptomsCountCount = Object.values(
  //         TotalsymptomsCountCountByDateKey
  //       );

  //       const countIsTakeSamplesZeroCount = Object.values(
  //         countIsTakeSamplesZeroCountByDataKey
  //       );
  //       const totalcountIsTakeSamplesZeroCount = Object.values(
  //         totalcountIsTakeSamplesZeroCountByDataKey
  //       );
  //       const totalIsInfluenzaAffectedCount = Object.values(
  //         totalIsInfluenzaAffectedCountByDataKey
  //       );
  //       const IsInfluenzaAffectedZeroCount = Object.values(
  //         IsInfluenzaAffectedZeroCountByDataKey
  //       );

  //       const result = {
  //         dateKey: dateKeys,
  //         participantConsentCount: participantConsentCounts,
  //         symptomsMembersCount: symptomsMembersCounts,
  //         patientsCount: patientsCountCount,
  //         TotalsymptomsCount: TotalsymptomsCountCount,
  //         IsTakeSamplesPostiveCount: countIsTakeSamplesZeroCount,
  //         IsTakeSamplesTotalCount: totalcountIsTakeSamplesZeroCount,
  //         IsInfluenzaAffectedTotalCount: totalIsInfluenzaAffectedCount,
  //         IsInfluenzaAffectedPostiveCount: IsInfluenzaAffectedZeroCount,
  //       };
  //       setparticipantConsent(result);
  //       return result;
  //     } else {
  //       console.error("Invalid data format:", response.data);
  //       return [];
  //     }
  //   } catch (error) {
  //     console.error("Error fetching household data:", error);
  //     return [];
  //   }
  // };

  const handleButtonClick = async (selectedCity) => {
    // getAgeForLILPatients(selectedCity, activeArea);
    Getusers(selectedCity, activeArea);
    //console.log("selectedCity", selectedCity, UserDetailsData);
    setActiveButton(selectedCity);
    await setActiveSection(selectedCity);
    // setChangeCards(true);
    await getusersCount(selectedCity, activeArea);
    await getCompletedHouseholdsCount(selectedCity, activeArea);
    getformDataSari(selectedCity);
    getformDataIli(selectedCity, activeArea);
  };

  const handleButtonClickAera = async (selectedCity) => {
    // getAgeForLILPatients(activeSection, selectedCity);
    Getusers(activeSection, selectedCity);
    //console.log("selectedCity", selectedCity);
    await setActiveArea(selectedCity);
    // setChangeCards(true);
    await getusersCount(activeSection, selectedCity);
    await getCompletedHouseholdsCount(activeSection, selectedCity);
    getformDataIli(activeSection, selectedCity);
    // getHealthCentersCount()
    // setActiveSection(selectedCity);
  };
  const handleButtonClickTypeSentinnel = (selectedCity) => {
    //console.log("selectedCity", selectedCity);
    setActiveType(selectedCity);
    setChangeCards(false);
    // setActiveSection(selectedCity);
  };
  const handleButtonClickTypeHouseHold = (selectedCity) => {
    //console.log("selectedCity", selectedCity);
    setActiveType(selectedCity);
    setChangeCards(true);
    // setActiveSection(selectedCity);
  };
  const gender = [
    {
      value: "Male",
      text: "Male",
    },
    {
      value: "Female",
      text: "Female",
    },
    {
      value: "P",
      text: "Prefer Not to Say",
    },
  ];

  const [selectGender, setSelectGender] = useState(gender);

  function filterValidFormData(arrayOfArrays) {
    //console.log(arrayOfArrays, "hello");
    return arrayOfArrays.map((innerArray) =>
      innerArray.filter((obj) => obj.FORM_DATA !== null && obj.FORM_DATA !== "")
    );
  }

  const getformDataIli = (City, Area) => {
    Axios.get(`${config.baseUrl}/formDataIli`).then((response) => {
      const formDataArray = response.data;
      //console.log(formDataArray[0], "999");

      let SumOfSwabId = 0;
      let SumOfInfluenzaPositiveCases = 0;
      let SumOfInfluenzaAffected = 0;
      let SumOfInfluenzaAnswer = 0;
      let SumOfMaleCount = 0;
      let SumOfFemaleCount = 0;

      formDataArray[0].forEach((item) => {
        if (item?.FORM_DATA && item.FORM_DATA.length > 5) {
          let temp = JSON.parse(item.FORM_DATA);
          const filterTempData = JSON.parse(temp.generalInformation);
          // , "temp1temp1","sectorName",'city');"Rural","Sonipat","Vishakapatnam","Urban"
          // console.log(
          //   filterTempData.city,
          //   filterTempData.sectorName,
          //   City,
          //   Area,
          //   "temp1temp1"
          // );
          if (
            (filterTempData.city !== null) &
            (filterTempData.sectorName !== null) &
            ((filterTempData.city === City) &
              (filterTempData.sectorName === Area ||
                filterTempData.sectorName === Area.toUpperCase() ||
                filterTempData.sectorName === Area.toLowerCase()) ||
              (City === "Both") &
                (filterTempData.sectorName === Area.toUpperCase() ||
                  filterTempData.sectorName === Area.toLowerCase() ||
                  filterTempData.sectorName === Area) &
                (filterTempData.city === "Vishakapatnam" ||
                  filterTempData.city === "Sonipat" ||
                  filterTempData.city === "VIZAG") ||
              (Area === "Both") &
                (filterTempData.city === City) &
                (filterTempData.sectorName === "Urban" ||
                  filterTempData.sectorName === "RURAL" ||
                  filterTempData.sectorName === "URBAN" ||
                  filterTempData.sectorName === "Rural" ||
                  filterTempData.sectorName === "urban" ||
                  filterTempData.sectorName === "rural") ||
              (City === "Both" &&
                Area === "Both" &&
                (filterTempData.city === "Vishakapatnam" ||
                  filterTempData.city === "Sonipat")))
            // (filterTempData.city !== null) &
            // (filterTempData.sectorName !== null) &
            // ((City === "Both") &
            //   (filterTempData.city === "Sonipat" ||
            //     filterTempData.city === "Vishakapatnam" ||
            //     filterTempData.city === "VIZAG"))
          ) {
            // console.log(
            //   filterTempData.city,
            //   filterTempData.sectorName,
            //   City,
            //   Area,
            //   "temp1temp1"
            // );
            if (
              temp?.houseHoldMemberModel &&
              Array.isArray(temp.houseHoldMemberModel)
            ) {
              temp.houseHoldMemberModel.forEach((member) => {
                if (
                  member?.generalRespondents &&
                  typeof member.generalRespondents === "string"
                ) {
                  let temp1 = JSON.parse(member.generalRespondents);

                  // Check if gender is specified and is a string
                  if (temp1?.gender && typeof temp1.gender === "string") {
                    if (temp1.gender.trim() === "Male") {
                      SumOfMaleCount++;
                    } else if (temp1.gender.trim() === "Female") {
                      SumOfFemaleCount++;
                    }
                  }

                  // Check if swabId is not null and is a number or a non-empty string
                  if (
                    temp1?.swabId &&
                    (typeof temp1.swabId === "number" ||
                      temp1.swabId.trim() !== "")
                  ) {
                    // If swabId is numeric, add it directly
                    //console.log(filterTempData, temp1.swabId,SumOfSwabId, "temp1temp1");
                    if (
                      (filterTempData.districtName === "Vishakapatnam" ||
                        filterTempData.districtName === "Sonipat") &&
                      temp1.swabId !== null
                    ) {
                      if (typeof temp1.swabId === "number") {
                        // //console.log(temp1.swabId, "check swabId ");
                        SumOfSwabId += temp1.swabId;
                      } else {
                        // If swabId is alphanumeric, treat it as 1 or its length
                        // //console.log(temp1.swabId, "check alphanumeric swabId");
                        SumOfSwabId += temp1.swabId.length; // Or you can set it to 1
                      }
                    }
                  }

                  // Check if isTakeSamples is 0 (influenza positive case)
                  if (temp1?.isTakeSamples === 0) {
                    // console.log(
                    //   temp1.isTakeSamples,
                    //   filterTempData.districtName,
                    //   "check influenza positive"
                    // );
                    SumOfInfluenzaPositiveCases++; // Increment the counter for influenza positive cases
                  }

                  // Check if isInfluenzaAffected is true
                  if (temp1?.isInfluenzaAffected) {
                    // //console.log(
                    //   temp1.isInfluenzaAffected,
                    //   "check influenza affected"
                    // );
                    SumOfInfluenzaAffected++; // Increment the counter for influenza affected patients
                  }

                  if (
                    temp1?.influenzaAnswer &&
                    (typeof temp1.influenzaAnswer === "number" ||
                      typeof temp1.influenzaAnswer === "string")
                  ) {
                    if (typeof temp1.influenzaAnswer === "number") {
                      // //console.log(
                      //   temp1.influenzaAnswer,
                      //   "check influenza answer"
                      // );
                      SumOfInfluenzaAnswer += temp1.influenzaAnswer;
                    } else {
                      // //console.log(
                      //   temp1.influenzaAnswer,
                      //   "check influenza answer positive"
                      // );
                      SumOfInfluenzaAnswer += temp1.influenzaAnswer.length;
                    }
                  }
                }
              });
            }
          } else {
            // console.log("filter values", City, Area,filterTempData.city);
          }
        }
      });

      //console.log("Total no of swabId's:", SumOfSwabId);
      //console.log(
      //   "Count of Influenza positive cases:",
      //   SumOfInfluenzaPositiveCases
      // );
      //console.log(
      //   "Count of Influenza affected patients:",
      //   SumOfInfluenzaAffected
      // );
      //console.log("Sum of Male count:", SumOfMaleCount);
      //console.log("Sum of Female count:", SumOfFemaleCount);

      setGeneralRespondentData({
        swabId: SumOfSwabId,
        isTakeSamples: SumOfInfluenzaPositiveCases, // Setting the count of influenza positive cases
        isInfluenzaAffected: SumOfInfluenzaAffected, // Setting the count of influenza affected patients
        influenzaAnswer: SumOfInfluenzaAnswer,
      });
    });
  };

  const getformDataPneumonia = () => {
    Axios.get(`${config.baseUrl}/formDataPneumonia`).then((response) => {
      const formDataArray = response.data;
      //console.log(formDataArray, "bye");

      let SumOfPositivePneumoniaCases = 0; // Initialize the counter for positive pneumonia cases

      formDataArray.forEach((item) => {
        if (item?.FORM_DATA && item.FORM_DATA.length > 5) {
          let temp = JSON.parse(item.FORM_DATA);

          if (temp?.costOfTreatment && temp.costOfTreatment !== null) {
            let temp1 = JSON.parse(temp.costOfTreatment);
            //console.log("isPneumonia value:", temp1?.isPneumonia); // Log the value of isPneumonia
            if (temp1?.isPneumonia === 0) {
              // Check if isPneumonia is 0 (positive case)
              //console.log(temp1.isPneumonia, "===");
              SumOfPositivePneumoniaCases++; // Increment the counter for positive pneumonia cases
            }
          }
        }
      });

      //console.log(
      //   "Count of positive Pneumonia cases:",
      //   SumOfPositivePneumoniaCases
      // ); // Logging the count

      // Set the count of positive pneumonia cases to the state variable costOfTreatment
      setCostOfTreatment({
        isPneumonia: SumOfPositivePneumoniaCases,
      });
    });
  };

  const getformDataSari = (City) => {
    // if(City=='vi')

    Axios.get(`${config.baseUrl}/formDataSari`)
      .then((response) => {
        const formDataArray = response.data;
        //console.log(formDataArray, "_______");

        let SumOfOpdIliCases = 0;
        let SumOfSariIcuPatients = 0;
        let SumOfSariCasesOfOxygen = 0;
        let SumOfSariDeathsConfirmed = 0;
        let SumOfSariPatients = 0;
        let SumOfSariMalesCount = 0;
        let SumOfSariFemalesCount = 0;
        let SumOfOxygenCylinders = 0;
        formDataArray[0].forEach((item) => {
          if (item?.FORM_DATA && item.FORM_DATA.length > 5) {
            let temp = JSON.parse(item.FORM_DATA);
            let tempcity = JSON.parse(temp.generalHealthInformationModel);

            if (
              (tempcity.city !== null) &
                (tempcity.city === City ||
                  (City === "Both" &&
                    (tempcity.city === "Vizag" ||
                      tempcity.city === "VIZAG" ||
                      tempcity.city === "Vishakapatnam" ||
                      tempcity.city === "Sonipat"))) ||
              (tempcity.city === "Vizag" || tempcity.city === "VIZAG") &
                ((City === "Vishakapatnam") &
                  (tempcity.city === "Vizag" || tempcity.city === "VIZAG"))
            ) {
              if (temp?.healthCenterModel && temp.healthCenterModel !== null) {
                let temp1 = JSON.parse(temp.healthCenterModel);
                // console.log(temp,temp1, "temp values");
                if (temp1?.opdIliCases) {
                  //console.log(temp1, "  =-= -=- = -=- ");

                  SumOfOpdIliCases += temp1.opdIliCases;
                }
                if (temp1?.sariPatientsICU) {
                  SumOfSariIcuPatients += temp1.sariPatientsICU;
                }
                if (temp1?.sariCasesOnOxygen) {
                  // console.log(temp1?.sariCasesOnOxygen, "temp values");
                  SumOfSariCasesOfOxygen += temp1.sariCasesOnOxygen;
                }
                if (temp1?.sariDeathsConfirmed) {
                  SumOfSariDeathsConfirmed += temp1.sariDeathsConfirmed;
                }
                if (temp1?.sariPatients) {
                  SumOfSariPatients += temp1.sariPatients;
                }
                if (temp1?.oxygenCylinders) {
                  SumOfOxygenCylinders += temp1.oxygenCylinders;
                }
              }
            } else {
              // if (temp?.healthCenterModel && temp.healthCenterModel !== null) {
              //   let temp1 = JSON.parse(temp.healthCenterModel);
              //   if (temp1?.opdIliCases) {
              //     //console.log(temp1, "  =-= -=- = -=- ");
              //     SumOfOpdIliCases += temp1.opdIliCases;
              //   }
              //   if (temp1?.sariPatientsICU) {
              //     SumOfSariIcuPatients += temp1.sariPatientsICU;
              //   }
              //   if (temp1?.sariCasesOnOxygen) {
              //     SumOfSariCasesOfOxygen += temp1.sariCasesOnOxygen;
              //   }
              //   if (temp1?.sariDeathsConfirmed) {
              //     SumOfSariDeathsConfirmed += temp1.sariDeathsConfirmed;
              //   }
              //   if (temp1?.sariPatients) {
              //     SumOfSariPatients += temp1.sariPatients;
              //   }
              // }
            }
          }
        });

        formDataArray[0].forEach((item) => {
          if (item?.FORM_DATA && item.FORM_DATA.length > 5) {
            let temp = JSON.parse(item.FORM_DATA);
            let tempcity = JSON.parse(temp.generalHealthInformationModel);

            if (
              (tempcity.city !== null) &
              (tempcity.city === City ||
                City === "Both" ||
                (City === "Vishakapatnam") &
                  (tempcity.city === "Vizag" ||
                    tempcity.city === "VIZAG" ||
                    tempcity.city === "Vishakapatnam") ||
                (City === "Sonipat") &
                  (tempcity.city === City || tempcity.city === "SONIPAT"))
            ) {
              if (temp?.deathPatientModel && temp.deathPatientModel !== null) {
                temp.deathPatientModel.forEach((patient) => {
                  // console.log(patient,temp, "temp values");
                  if (patient?.gender && typeof patient.gender === "string") {
                    if (patient.gender.trim() === "Male") {
                      SumOfSariMalesCount += 1;
                    } else if (patient.gender.trim() === "Female") {
                      SumOfSariFemalesCount += 1;
                    }
                  }
                });
              }
            }
          }
        });

        setHealthCenterData({
          opdIliCases: SumOfOpdIliCases,
          sariCasesOnOxygen: SumOfSariIcuPatients,
          sariPatientsICU: SumOfSariCasesOfOxygen,
          sariDeathsConfirmed: SumOfSariDeathsConfirmed,
          sariPatients: SumOfSariPatients,
        });

        setDeathPatientModel({
          sariMaleCount: SumOfSariMalesCount,
          sariFemaleCount: SumOfSariFemalesCount,
        });
      })
      .catch((error) => {
        console.error("Error fetching formDataIli:", error);
      });
  };

  const getusersCount = async (activeSection, activeArea) => {
    try {
      const response = await Axios.get(
        `${config.baseUrl}/usersCount/${activeSection}/${activeArea}`
      );

      //console.log("Response: usersCount", response.data); // Logging the response data

      setVariables((prevUserData) => ({
        ...prevUserData,
        usersCount: response.data.total_users,
      }));
    } catch (error) {
      //console.log("Error:", error); // Log any errors that occur during the API call
    }
  };

  const getCompletedHouseholdsCount = async (City, Area) => {
    try {
      const res = await Axios.get(
        `${config.baseUrl}/getCompletedHouseholdsCount`
      );
      const recordsets = res.data.recordsets[0];
      let totalHouseholdsCount = 0;
      let completedHouseholdsCount = 0;

      recordsets.forEach((item, index) => {
        if (item.FORM_DATA) {
          try {
            const filterTempData = JSON.parse(item.FORM_DATA);

            // Ensure generalInformation is a valid JSON string before parsing
            if (filterTempData && filterTempData.generalInformation) {
              const filterGeneralInformation = JSON.parse(
                filterTempData.generalInformation
              );
              const { city, sectorName } = filterGeneralInformation;

              const isSectorMatch =
                sectorName === Area || sectorName === Area.toUpperCase();
              const isUrbanOrRural =
                sectorName === "Urban" ||
                sectorName === "URBAN" ||
                sectorName === "Rural" ||
                sectorName === "RURAL";
              const isSpecificCity =
                city === "Vishakapatnam" || city === "Sonipat";

              if (
                city !== null &&
                sectorName !== null &&
                ((city === City && isSectorMatch) ||
                  (City === "Both" && isSectorMatch && isSpecificCity) ||
                  (Area === "Both" && city === City && isUrbanOrRural) ||
                  (City === "Both" && Area === "Both" && isSpecificCity))
              ) {
                if (item.FORM_TYPE !== null && item.STATUS !== null) {
                  if (item.FORM_TYPE === 5) {
                    totalHouseholdsCount++;
                    if (item.STATUS === 3) {
                      completedHouseholdsCount++;
                    }
                  }
                }
              }
            } else {
              // console.warn(
              //   `generalInformation is invalid JSON for record ${index + 1}`
              // );
            }
          } catch (error) {
            console.error(`Error parsing JSON for record ${index + 1}:`, error);
          }
        } else {
          console.warn(`FORM_DATA is undefined for record ${index + 1}`);
        }
      });

      setVariables((prevUserData) => ({
        ...prevUserData,
        totalHouseholdsCount: totalHouseholdsCount,
        completedHouseholdsCount: completedHouseholdsCount,
      }));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const getUserCountByCity = () => {
    Axios.get(`${config.baseUrl}/getUserCountByCity`)
      .then((res) => {
        //console.log(res);
        setVariables((prevUserData) => ({
          ...prevUserData,
          usersCountByCity: res.data.recordset,
        }));
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  const backgroundColors = ["#FFFFFF"];

  const getHealthCentersCount = () => {
    const cities = ["Haryana", "Vizag"]; // Set the list of cities

    // Make a GET request to the server with the list of cities as query parameters
    Axios.get(`${config.baseUrl}/getHealthCentersCount`, {
      params: { cities: cities },
    })
      .then((resp) => {
        //console.log("respect", resp);
        setVariables((prevUserData) => ({
          ...prevUserData,
          healthCentersCount: resp.data.recordset[0].facility_count,
        }));
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };

  const TypeOfTimeBased = (type) => {
    setTypeOfTime(type);
  };

  // useEffect(() => {
  //   if (typeOfTime) {
  //     getAgeForLILPatients(activeSection, activeArea);
  //   }
  // }, [typeOfTime, activeSection, activeArea]);

  return (
    <div>
      <div>
        <>
          <div className="dashboardSection1">
            <div className="filter">
              <h3 className="pageheading">Dashboard </h3>
              <div className="primaryDataCardBody">
                {/* <MDBBtnGroup className="btnGrp1"> */}
                <div className="btn-group shadow-0" role="group">
                  {/* Role based login */}
                  {UserDetailsData.ROLE === "0" ? (
                    <button
                      className={`btn custom-btn-color ${
                        activeSection === "Both" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("Both")}
                      style={{ padding: "4% 11%", fontSize: "11px" }}
                    >
                      <span
                        className={activeSection === "Both" ? "black-text" : ""}
                        style={{ fontWeight: "bold", fontSize: "11px" }}
                      >
                        ALL
                      </span>
                    </button>
                  ) : null}
                  {UserDetailsData.CITY === "Vishakapatnam" ||
                  UserDetailsData.ROLE === "0" ? (
                    <button
                      className={`btn custom-btn-color ${
                        activeSection === "Vishakapatnam" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("Vishakapatnam")}
                      style={{ padding: "4% 11%", fontSize: "11px" }}
                    >
                      <span
                        className={
                          activeSection === "Vishakapatnam" ? "black-text" : ""
                        }
                        style={{ fontWeight: "bold", fontSize: "11px" }}
                      >
                        Vishakapatnam
                      </span>
                    </button>
                  ) : null}

                  {UserDetailsData.CITY === "Sonipat" ||
                  UserDetailsData.ROLE === "0" ? (
                    <button
                      className={`btn custom-btn-color ${
                        activeSection === "Sonipat" ? "active" : ""
                      }`}
                      onClick={() => handleButtonClick("Sonipat")}
                      style={{ padding: "4% 11%", fontSize: "11px" }}
                    >
                      <span
                        className={
                          activeSection === "Sonipat" ? "black-text" : ""
                        }
                        style={{ fontWeight: "", fontSize: "11px" }}
                      >
                        Sonipat
                      </span>
                    </button>
                  ) : null}
                </div>
                {/* </MDBBtnGroup> */}

                {/* <MDBBtnGroup> */}
                <div
                  className="btn-group"
                  role="group"
                  style={{ display: "flex",marginLeft:'15%'}}

                >
                  <button
                    className={`btn custom-btn-color ${
                      activeArea === "Both" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClickAera("Both")}
                    style={{ padding: "4% 11%", fontSize: "11px" }}
                  >
                    <span
                      className={activeSection === "Both" ? "black-text" : ""}
                      style={{ fontWeight: "bold", fontSize: "11px" }}
                    >
                      ALL
                    </span>
                  </button>
                  <button
                    type="button"
                    className={`btn custom-btn-color ${
                      activeArea === "Urban" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClickAera("Urban")}
                    style={{ padding: "4% 11%", fontSize: "11px" }}
                  >
                    Urban
                  </button>
                  <button
                    type="button"
                    className={`btn custom-btn-color ${
                      activeArea === "Rural" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClickAera("Rural")}
                    style={{ padding: "4% 11%", fontSize: "11px" }}
                  >
                    Rural
                  </button>
                </div>
                {/* </MDBBtnGroup> */}

                {/* <MDBBtnGroup> */}
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    className={`btn custom-btn-color ${
                      activeType === "HouseHold" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClickTypeHouseHold("HouseHold")}
                    style={{ padding: "4% 11%", fontSize: "11px" }}
                  >
                    HouseHold
                  </button>
                  <button
                    type="button"
                    className={`btn custom-btn-color ${
                      activeType === "Sentinnel" ? "active" : ""
                    }`}
                    onClick={() => handleButtonClickTypeSentinnel("Sentinnel")}
                    style={{ padding: "4% 11%", fontSize: "11px" }}
                  >
                    Sentinnel
                  </button>

                  {activeSection === "HouseHold" && (
                    <MDBRow
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginRight: "-195px",
                        marginLeft: "-15px",
                      }}
                    >
                      <MDBCol>
                        <MDBCard className="houseHoldCardInfluenza">
                          <MDBCardBody>
                            {error ? (
                              <p>Error: {error}</p>
                            ) : (
                              <div>
                                <p className="houseHoldCardText">
                                  TOTAL INFLUENZA <br />
                                  CASES:
                                </p>
                                <p className="countText">
                                  {generalRespondentData.isTakeSamples}
                                </p>
                              </div>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol>
                        <MDBCard className="houseHoldCardInfluenza4">
                          <MDBCardBody>
                            {error ? (
                              <p>Error: {error}</p>
                            ) : (
                              <div>
                                <p className="houseHoldCardText2">
                                  INFLUENZA IN POSITIVE <br />
                                  SAMPLES:
                                </p>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    marginLeft: "14rem",
                                    marginTop: "-1rem",
                                  }}
                                >
                                  {generalRespondentData.influenzaAnswer}
                                </p>
                              </div>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>

                      <MDBCol>
                        <MDBCard className="houseHoldCardInfluenza2">
                          <MDBCardBody>
                            {error ? (
                              <p>Error: {error}</p>
                            ) : (
                              <div>
                                <p className="houseHoldCardText">SARI Males:</p>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    color: "skyblue",
                                    marginLeft: "13rem",
                                    marginTop: "-4rem",
                                    fontFamily: "roboto",
                                  }}
                                >
                                  {deathPatientModel.sariMaleCount}
                                </p>
                                <p className="houseHoldCardText4">
                                  SARI Females:
                                </p>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    color: "skyblue",
                                    marginLeft: "13rem",
                                    marginTop: "-4rem",
                                  }}
                                >
                                  {deathPatientModel.sariFemaleCount}
                                </p>
                              </div>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <div>
                        <MDBRow>
                          <MDBCol>
                            <MDBCard className="houseHoldCardInfluenza3">
                              <MDBCardBody>
                                {error ? (
                                  <p>Error: {error}</p>
                                ) : (
                                  <div>
                                    <p className="houseHoldCardText1">
                                      INFLUENZA POSITIVE <br /> CASES:
                                    </p>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "40px",
                                        marginLeft: "14rem",
                                        marginTop: "-1rem",
                                      }}
                                    >
                                      {
                                        generalRespondentData.isInfluenzaAffected
                                      }
                                    </p>
                                  </div>
                                )}
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBRow>
                  )}
                  {activeSection === "Sentinnel" && (
                    <MDBRow
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginRight: "78px",
                        marginLeft: "-15px",
                      }}
                    >
                      <MDBCol>
                        <MDBCard className="sentinnelCard">
                          <MDBCardBody>
                            {error ? (
                              <p>Error: {error}</p>
                            ) : (
                              <div>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "40px",
                                    color: "skyblue",
                                    marginLeft: "12rem",
                                    fontFamily: "roboto",
                                    display: "flex",
                                    gap: "5%",
                                    alignItems: "center",
                                  }}
                                >
                                  {healthCenterData.opdIliCases}
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10%",
                                      marginRight: "3%",
                                    }}
                                  >
                                    {activeSection !== "Both" && (
                                      <b style={{ fontSize: "15px" }}>
                                        {activeSection}
                                      </b>
                                    )}
                                  </div>
                                </p>
                                <p className="sentinnelCardTextOpd">
                                  OPD ILI CASES:
                                </p>
                              </div>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol>
                        <MDBCard className="sentinnelCard1">
                          <MDBCardBody>
                            {error ? (
                              <p>Error: {error}</p>
                            ) : (
                              <div>
                                <p className="sentinnelCardTextOxygen"></p>
                                <p className="countText">
                                  {healthCenterData.sariCasesOnOxygen}
                                </p>
                              </div>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <MDBCol>
                        <MDBCard className="sentinnelCard2">
                          <MDBCardBody>
                            {error ? (
                              <p>Error: {error}</p>
                            ) : (
                              <div>
                                <p className="sentinnelCardTextIcu">
                                  SARI ICU PATIENTS:
                                </p>
                                <p className="countText">
                                  {healthCenterData.sariPatientsICU}
                                </p>
                              </div>
                            )}
                          </MDBCardBody>
                        </MDBCard>
                      </MDBCol>
                      <MDBRow>
                        <MDBCol>
                          <MDBCard className="sentinnelCard3">
                            <MDBCardBody>
                              {error ? (
                                <p>Error: {error}</p>
                              ) : (
                                <div>
                                  <p className="sentinnelCardTextDeaths">
                                    SARI DEATHS:
                                  </p>
                                  <p className="countText">
                                    {healthCenterData.sariDeathsConfirmed}
                                  </p>
                                </div>
                              )}
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                        <MDBCol>
                          <MDBCard className="sentinnelCard4">
                            <MDBCardBody>
                              {error ? (
                                <p>Error: {error}</p>
                              ) : (
                                <div>
                                  <p className="sentinnelCardTextSariPatients">
                                    SARI PATIENTS:
                                  </p>
                                  <p className="countText">
                                    {healthCenterData.sariPatients}
                                  </p>
                                </div>
                              )}
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                        <MDBCol>
                          <MDBCard className="sentinnelCard5">
                            <MDBCardBody>
                              {error ? (
                                <p>Error: {error}</p>
                              ) : (
                                <div>
                                  <p className="sentinnelCardTextNasalSwab">
                                    NASALSWABS TAKEN:
                                  </p>
                                  <p className="countText">
                                    {generalRespondentData.swabId}
                                  </p>
                                </div>
                              )}
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol>
                          <MDBCard className="sentinnelCard6">
                            <MDBCardBody>
                              {error ? (
                                <p>Error: {error}</p>
                              ) : (
                                <div>
                                  <p className="sentinnelCardTextSamples">
                                    {/* POSITIVE SAMPLES: */}
                                    COLLECTION OF SAMPLES:
                                  </p>
                                  <p className="countText">
                                    {generalRespondentData.isTakeSamples}
                                  </p>
                                </div>
                              )}
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                        <MDBCol>
                          <MDBCard className="sentinnelCard7">
                            <MDBCardBody>
                              {error ? (
                                <p>Error: {error}</p>
                              ) : (
                                <div>
                                  <p className="sentinnelCardTextPneumonia">
                                    PNEUMONIA CASES:
                                  </p>
                                  <p className="countText">
                                    {/* {costOfTreatment.isPneumonia} */}
                                    50
                                  </p>
                                </div>
                              )}
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                        <MDBCol></MDBCol>
                      </MDBRow>
                    </MDBRow>
                  )}
                </div>
              </div>
            </div>

            <MDBRow className="dashboardCardsMain">
              <MDBCol className="dashboardCards">
                {changeCards && (
                  <>
                    <MDBCard className="houseHoldCard">
                      <MDBCardBody>
                        <div>
                          <MDBIcon icon="person" />
                        </div>
                        <div className="houseHoldCardBody">
                          <div className="houseHoldTextContainer">
                            <h5 className="houseHoldTitle">HouseHold Status</h5>
                            <p className="houseHoldContent">
                              <p
                                className="houseHoldPerformanceText"
                                style={{ margin: "0" }}
                              >
                                completed{" "}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10%",
                                  marginRight: "3%",
                                }}
                              >
                                {activeSection !== "Both" && (
                                  <b style={{ fontSize: "15px" }}>
                                    {activeSection}
                                  </b>
                                )}
                                {activeArea !== "Both" && (
                                  <b style={{ fontSize: "15px" }}>
                                    {activeArea}
                                  </b>
                                )}
                              </div>
                            </p>
                          </div>
                          <div>
                            <p
                              style={{
                                color: "white",
                                fontSize: "26px",
                                margin: "0",
                              }}
                            >
                              {" "}
                              {variables.completedHouseholdsCount}/
                              {variables.totalHouseholdsCount}
                            </p>
                          </div>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="healthWorkers">
                      <MDBCardBody>
                        <MDBRow style={{ alignItems: "center", gap: "4%" }}>
                          <div className="groupIcon">
                            <box-icon name="user" />
                          </div>
                          <div>
                            <p
                              style={{
                                color: "skyblue",
                                fontSize: "30px",
                                fontWeight: "bold",
                                marginLeft: "5%",
                                color: "black",
                                marginBottom: 0,
                                display: "flex",
                                gap: "5%",
                                alignItems: "center",
                              }}
                            >
                              {/* {//console.log(props.count, "props.count")} */}
                              {variables.usersCount}
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10%",
                                  marginRight: "3%",
                                }}
                              >
                                {activeSection !== "Both" && (
                                  <b style={{ fontSize: "15px" }}>
                                    {activeSection}
                                  </b>
                                )}
                                {activeArea !== "Both" && (
                                  <b style={{ fontSize: "15px" }}>
                                    {activeArea}
                                  </b>
                                )}
                              </div>
                            </p>{" "}
                            <MDBCardTitle
                              style={{
                                fontSize: "15px",
                                marginLeft: "10px",
                                fontWeight: "bold",
                                marginBottom: "0px",
                                color: "#00000070",
                              }}
                            >
                              Total Health Workers
                            </MDBCardTitle>
                          </div>
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="healthWorkers">
                      <MDBCardBody>
                        {error ? (
                          <p>Error: {error}</p>
                        ) : (
                          <div
                            className="houseHoldCardBody"
                            style={{ alignItems: "center", gap: " 4%" }}
                          >
                            <div
                              className="groupIcon"
                              style={{ padding: "6px" }}
                            >
                              <MDBIcon
                                fas
                                icon="hospital-alt"
                                style={{ fontSize: "30px" }}
                                className="CardsIcon"
                              />
                            </div>
                            <div>
                              {" "}
                              <p
                                style={{
                                  color: "skyblue",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  marginLeft: "5%",
                                  color: "black",
                                  marginBottom: "3%",
                                  display: "flex",
                                  gap: "5%",
                                  alignItems: "center",
                                }}
                              >
                                {healthCenterData.opdIliCases}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10%",
                                    marginRight: "3%",
                                  }}
                                >
                                  {activeSection !== "Both" && (
                                    <b style={{ fontSize: "15px" }}>
                                      {activeSection}
                                    </b>
                                  )}
                                </div>
                              </p>
                              <h4
                                className="sentinnelCardTextOpd"
                                style={{
                                  fontSize: "15px",
                                  marginLeft: "8px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  color: "#00000070",
                                }}
                              >
                                OPD ILI Cases
                              </h4>
                            </div>
                          </div>
                        )}
                      </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="healthWorkers">
                      <MDBCardBody>
                        {error ? (
                          <p>Error: {error}</p>
                        ) : (
                          <div
                            className="houseHoldCardBody"
                            style={{ alignItems: "center", gap: " 4%" }}
                          >
                            <div
                              className="groupIcon"
                              style={{ padding: "6px" }}
                            >
                              <MDBIcon
                                fas
                                icon="hospital-alt"
                                style={{ fontSize: "30px" }}
                                className="CardsIcon"
                              />
                            </div>
                            <div>
                              <p
                                style={{
                                  color: "skyblue",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  marginLeft: "5%",
                                  color: "black",
                                  marginBottom: "3%",
                                  display: "flex",
                                  gap: "5%",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {generalRespondentData.isTakeSamples}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10%",
                                    marginRight: "3%",
                                  }}
                                >
                                  {activeSection !== "Both" && (
                                    <b style={{ fontSize: "15px" }}>
                                      {activeSection}
                                    </b>
                                  )}
                                  {activeArea !== "Both" && (
                                    <b style={{ fontSize: "15px" }}>
                                      {activeArea}
                                    </b>
                                  )}
                                </div>
                              </p>
                              <h4
                                className="sentinnelCardTextSamples"
                                style={{
                                  fontSize: "15px",
                                  marginLeft: "8px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  color: "#00000070",
                                }}
                              >
                                Collection Of Samples
                              </h4>
                            </div>
                          </div>
                        )}
                      </MDBCardBody>
                    </MDBCard>
                  </>
                )}
                {!changeCards && (
                  <>
                    <MDBCard className="healthWorkers">
                      <MDBCardBody>
                        {error ? (
                          <p>Error: {error}</p>
                        ) : (
                          <div
                            div
                            className="houseHoldCardBody"
                            style={{ alignItems: "center", gap: " 4%" }}
                          >
                            <div
                              className="groupIcon"
                              style={{ padding: "11px" }}
                            >
                              <MDBIcon
                                fas
                                icon="hospital-alt"
                                style={{ fontSize: "30px" }}
                                className="CardsIcon"
                              />
                            </div>
                            <div>
                              <p
                                style={{
                                  color: "skyblue",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  marginLeft: "5%",
                                  color: "black",

                                  display: "flex",
                                  gap: "5%",
                                  alignItems: "center",
                                  marginBottom: "4%",
                                }}
                              >
                                {/* {//console.log(props.count, "props.count")} */}
                                {generalRespondentData.isTakeSamples}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10%",
                                    marginRight: "3%",
                                  }}
                                >
                                  {activeSection !== "Both" && (
                                    <b style={{ fontSize: "15px" }}>
                                      {activeSection}
                                    </b>
                                  )}
                                  {activeArea !== "Both" && (
                                    <b style={{ fontSize: "15px" }}>
                                      {activeArea}
                                    </b>
                                  )}
                                </div>
                              </p>{" "}
                              <h4
                                className="sentinnelCardTextOpd"
                                style={{
                                  fontSize: "15px",
                                  marginLeft: "5%",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  color: "#00000070",
                                  width: "100%",
                                }}
                              >
                                Total Influenza Cases{" "}
                              </h4>
                            </div>
                          </div>
                        )}
                      </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="healthWorkers">
                      <MDBCardBody>
                        {error ? (
                          <p>Error: {error}</p>
                        ) : (
                          <div>
                            <div
                              className="houseHoldCardBody"
                              style={{ alignItems: "center", gap: " 4%" }}
                            >
                              <div
                                className="groupIcon"
                                style={{ padding: "6px" }}
                              >
                                <img
                                  className="CardsIcon"
                                  style={{ height: " 42px" }}
                                  src={femaleIcon}
                                  alt="User Icon"
                                />
                              </div>
                              <div>
                                {/* <p
                                  style={{
                                    color: "skyblue",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    marginLeft: "5%",
                                    color: "black",
                                    marginBottom: "3%",
                                  }}
                                >
                                  {deathPatientModel.sariFemaleCount}
                                </p> */}
                                <p
                                  style={{
                                    color: "skyblue",
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                    marginLeft: "5%",
                                    color: "black",
                                    marginBottom: "4%",
                                    display: "flex",
                                    gap: "5%",
                                    alignItems: "center",
                                  }}
                                >
                                  {deathPatientModel.sariFemaleCount}
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10%",
                                      marginRight: "3%",
                                    }}
                                  >
                                    {activeSection !== "Both" && (
                                      <b style={{ fontSize: "15px" }}>
                                        {activeSection}
                                      </b>
                                    )}
                                  </div>
                                </p>
                                <h4
                                  className="sentinnelCardTextOpd"
                                  style={{
                                    fontSize: "15px",
                                    marginLeft: "5%",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                    color: "#00000070",
                                    width: "100%",
                                  }}
                                >
                                  SARI Deaths Females{" "}
                                </h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="healthWorkers">
                      <MDBCardBody>
                        {error ? (
                          <p>Error: {error}</p>
                        ) : (
                          <div
                            className="houseHoldCardBody"
                            style={{ alignItems: "center", gap: " 4%" }}
                          >
                            <div
                              className="groupIcon"
                              style={{ padding: "6px" }}
                            >
                              <img
                                className="CardsIcon"
                                style={{ height: " 42px" }}
                                src={maleIcon}
                                alt="User Icon"
                              />
                            </div>
                            <div>
                              {/* <p
                                style={{
                                  color: "skyblue",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  marginLeft: "5%",
                                  color: "black",
                                  marginBottom: "3%",
                                }}
                              >
                                {deathPatientModel.sariMaleCount}
                              </p> */}
                              <p
                                style={{
                                  color: "skyblue",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  marginLeft: "5%",
                                  color: "black",
                                  marginBottom: "4%",
                                  display: "flex",
                                  gap: "5%",
                                  alignItems: "center",
                                }}
                              >
                                {deathPatientModel.sariMaleCount}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10%",
                                    marginRight: "3%",
                                  }}
                                >
                                  {activeSection !== "Both" && (
                                    <b style={{ fontSize: "15px" }}>
                                      {activeSection}
                                    </b>
                                  )}
                                </div>
                              </p>
                              <h4
                                className="sentinnelCardTextOpd"
                                style={{
                                  fontSize: "15px",
                                  marginLeft: "5%",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  color: "#00000070",
                                  width: "100%",
                                }}
                              >
                                SARI Deaths Males
                              </h4>
                            </div>
                          </div>
                        )}
                      </MDBCardBody>
                    </MDBCard>
                    <MDBCard className="healthWorkers">
                      <MDBCardBody>
                        {error ? (
                          <p>Error: {error}</p>
                        ) : (
                          <div
                            div
                            className="houseHoldCardBody"
                            style={{ alignItems: "center", gap: " 4%" }}
                          >
                            <div
                              className="groupIcon"
                              style={{ padding: "11px" }}
                            >
                              <MDBIcon
                                fas
                                icon="plus-square"
                                style={{ fontSize: "32px" }}
                                className="CardsIcon"
                              />
                            </div>
                            <div>
                              {/* <p
                                style={{
                                  color: "skyblue",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  // marginLeft: "5%",
                                  color: "black",
                                  marginBottom: "3%",
                                }}
                              >
                                {healthCenterData.sariCasesOnOxygen}
                              </p> */}

                              <p
                                style={{
                                  color: "skyblue",
                                  fontSize: "30px",
                                  fontWeight: "bold",
                                  marginLeft: "5%",
                                  color: "black",
                                  marginBottom: "4%",
                                  display: "flex",
                                  gap: "5%",
                                  alignItems: "center",
                                }}
                              >
                                {healthCenterData.sariCasesOnOxygen}
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "10%",
                                    marginRight: "3%",
                                  }}
                                >
                                  {activeSection !== "Both" && (
                                    <b style={{ fontSize: "15px" }}>
                                      {activeSection}
                                    </b>
                                  )}
                                </div>
                              </p>
                              <h4
                                className="sentinnelCardTextOpd"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  color: "#00000070",
                                  marginLeft: "5%",
                                  width: "100%",
                                }}
                              >
                                {/* INFLUENZA POSITIVE CASES */}
                                SARI OXYGEN CASES
                              </h4>
                            </div>
                          </div>
                        )}
                      </MDBCardBody>
                      {/* <MDBCard className="sentinnelCard1">
                        <MDBCardBody>
                          {error ? (
                            <p>Error: {error}</p>
                          ) : (
                            <div>
                              <p className="sentinnelCardTextOxygen">
                                SARI OXYGEN CASES:
                              </p>
                              <p className="countText">
                                {healthCenterData.sariCasesOnOxygen}
                              </p>
                            </div>
                          )}
                        </MDBCardBody>
                      </MDBCard> */}
                    </MDBCard>
                  </>
                )}
              </MDBCol>
            </MDBRow>
          </div>

          <section className="detai lsContainerMain">
            <div className="detailsContainer">
              {changeCards ? (
                <MDBRow style={{ width: "70%" }}>
                  <MDBCol style={{ padding: "0" }}>
                    <MDBCard className="graphCard">
                      <MDBCardHeader className="card-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <MDBIcon
                            fas
                            icon="chart-line"
                            className="graphIcon"
                          />
                          <b>HOUSEHOLD ILI DATA</b>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10%",
                            marginRight: "3%",
                          }}
                        >
                          {activeSection !== "Both" && <b>{activeSection}</b>}
                          {activeArea !== "Both" && <b>{activeArea}</b>}
                        </div>
                      </MDBCardHeader>
                      <MDBCardBody>
                        <GraphComponent
                          City={activeSection}
                          Area={activeArea}
                        />
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              ) : (
                <MDBRow style={{ width: "70%" }}>
                  <MDBCol style={{ padding: "0" }}>
                    <MDBCard className="graphCard">
                      <MDBCardHeader className="card-header">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <MDBIcon
                            fas
                            icon="chart-line"
                            className="graphIcon"
                          />
                          <b>SENTINNEL DATA</b>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "10%",
                            marginRight: "3%",
                          }}
                        >
                          {activeSection !== "Both" && <b>{activeSection}</b>}
                          {/* {activeArea !== "Both" && <b>{activeArea}</b>} */}
                        </div>
                      </MDBCardHeader>
                      <MDBCardBody>
                        <SentinnelGraph
                          City={activeSection}
                          Area={activeArea}
                        />
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow style={{ width: "30%" }}>
                <MDBCol style={{ marginTop: "9px", marginLeft: "0" }}>
                  <MDBCard className="detailsCard">
                    <MDBCardHeader className="detailsCardHeader">
                      <MDBIcon
                        fas
                        icon="plus-square"
                        className="hospitalIcon"
                      />
                      <b>Active Health Workers</b>
                    </MDBCardHeader>

                    <MDBCardBody className="detailsCardBody">
                      <MDBTable align="middle">
                        <MDBTableHead>
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">City</th>
                            <th scope="col">Region</th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          {usersData.map((user, index) => (
                            <tr key={user.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div key={index}>
                                    <img
                                      className="userIcon"
                                      style={{
                                        marginLeft: "0.1rem",
                                        marginTop: "0.1rem",
                                      }}
                                      src={
                                        user.GENDER === "Male"
                                          ? maleIcon
                                          : femaleIcon
                                      }
                                      alt="User Icon"
                                    />
                                  </div>
                                  <div className="ms-3">
                                    <p className="fw-bold mb-1">
                                      {user.FIRST_NAME}
                                    </p>
                                    <p className="text-muted mb-0">
                                      {user.LAST_NAME}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p className="fw-normal mb-1">{user.EMAIL}</p>
                              </td>
                              <td
                                style={{
                                  fontSize: "13.5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {user.CITY}
                              </td>
                              <td
                                style={{
                                  fontSize: "13.5px",
                                  fontWeight: "bold",
                                }}
                              >
                                {user.REGION}
                              </td>
                            </tr>
                          ))}
                        </MDBTableBody>
                      </MDBTable>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </div>
          </section>
        </>
      </div>

      {/* convert into  GraphsBody  Component */}
      {/* <div className="DynamicGraphList">
        <div className="ILIsymptomaticpatients">
          <div className="headding">
            <h1>ILI symptomatic patients </h1>
            <div>
              <button
                onClick={() => TypeOfTimeBased("weekly")}
                style={{
                  backgroundColor: typeOfTime === "weekly" ? "#ff842d" : null,
                }}
              >
                Weekly
              </button>
              <button
                onClick={() => TypeOfTimeBased("monthly")}
                style={{
                  backgroundColor: typeOfTime === "monthly" ? "#ff842d" : null,
                }}
              >
                Monthly
              </button>
            </div>
          </div>

          <GraphDynamicComponent
            keys={participantConsent.dateKey}
            FirstValue={participantConsent.participantConsentCount}
            SecondValue={participantConsent.symptomsMembersCount}
            FirstKey={"participantConsentCount"}
            SecondKey={"symptomsMembersCount"}
          />
        </div>
        <div className="PatientsTestedInfluenza">
          <div className="headding">
            <h1>Patients tested for influenza </h1>
            <div>
              <button
                onClick={() => TypeOfTimeBased("weekly")}
                style={{
                  backgroundColor: typeOfTime === "weekly" ? "#ff842d" : null,
                }}
              >
                Weekly
              </button>
              <button
                onClick={() => TypeOfTimeBased("monthly")}
                style={{
                  backgroundColor: typeOfTime === "monthly" ? "#ff842d" : null,
                }}
              >
                Monthly
              </button>
            </div>
          </div>

          <GraphDynamicComponent
            keys={participantConsent.dateKey}
            FirstValue={participantConsent.patientsCount}
            SecondValue={participantConsent.IsTakeSamplesPostiveCount}
            FirstKey={"patientsCount"}
            SecondKey={"IsTakeSamplesPostiveCount"}
          />
        </div>
      </div> */}
      <GraphsBody/>
    </div>
  );
};

export default DashboardV1;
