// import React, { useEffect, useState } from "react";
// import Axios from "axios";
// import ReactECharts from "echarts-for-react";
// import { format } from "date-fns";
// import config from "../../config";

// const GraphComponent = () => {
//   const [chartData, setChartData] = useState({
//     xAxis: { data: [] },
//     yAxis: { type: "value", name: "Cases Count" },
//     series: [],
//   });

//   // useEffect(() => {
//   //   Axios.get(`${config.baseUrl}/formDataSari`)
//   //     .then((res) => {
//   //       console.log("asjskbsjkabjkakbs", res);
//   //       const responseData = res.data[0];
//   //       const data = responseData;
//   //       console.log("asjskbsjkabjkakbs", data);

//   //       const xAxisData = data.map((record) => {
//   //         const formData = JSON.parse(record.FORM_DATA);

//   //         const statusDate = new Date(record.STATUS_DATE);
//   //         return format(statusDate, "MMM d");
//   //       });

//   //       const sariPatientsData = data.map((record) => {
//   //         const formData = JSON.parse(record.FORM_DATA);
//   //         console.log("asjskbsjkabjkakbs", formData );
//   //         const healthCenterModel = JSON.parse(formData.healthCenterModel);

//   //         return healthCenterModel.sariPatients || 0;
//   //       });
//   //       console.log("asjskbsjkabjkakbs", sariPatientsData);
//   //       const sariPatientsICUData = data.map((record) => {
//   //         const formData = JSON.parse(record.FORM_DATA);
//   //         const healthCenterModel = JSON.parse(formData.healthCenterModel);
//   //         return healthCenterModel.sariPatientsICU || 0;
//   //       });

//   //       const sariDeathsConfirmedData = data.map((record) => {
//   //         const formData = JSON.parse(record.FORM_DATA);
//   //         const healthCenterModel = JSON.parse(formData.healthCenterModel);
//   //         return healthCenterModel.sariDeathsConfirmed || 0;
//   //       });

//   //       setChartData({
//   //         xAxis: {
//   //           data: xAxisData,
//   //           name: "Date",
//   //           nameLocation: "center",
//   //           nameGap: 40,
//   //           nameTextStyle: { fontSize: 16 },
//   //         },
//   //         yAxis: {
//   //           type: "value",
//   //           name: "Cases Count",
//   //           nameLocation: "center",
//   //           nameGap: 40,
//   //           nameTextStyle: { fontSize: 16 },
//   //         },
//   //         series: [
//   //           {
//   //             name: "SARI Patients",
//   //             type: "bar",
//   //             data: sariPatientsData,
//   //             itemStyle: { color: "#1f77b4" }, // Blue color
//   //           },
//   //           {
//   //             name: "SARI Patients ICU",
//   //             type: "bar",
//   //             data: sariPatientsICUData,
//   //             itemStyle: { color: "#ff7f0e" }, // Orange color
//   //           },
//   //           {
//   //             name: "SARI Deaths Confirmed",
//   //             type: "bar",
//   //             data: sariDeathsConfirmedData,
//   //             itemStyle: { color: "#2ca02c" }, // Green color
//   //           },
//   //         ],
//   //       });
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error fetching data:", error);
//   //     });
//   // }, []);

//   useEffect(() => {
//     Axios.get(`${config.baseUrl}/formDataSari`)
//       .then((res) => {
//         console.log("formDataSari response:", res);
//         const sariFormData = res.data[0];
//         console.log("sariFormData:", sariFormData);
//         const graphData = sariFormData.map((record) => {
//           const formData = JSON.parse(record.FORM_DATA);
//           const statusDate = new Date(record.STATUS_DATE);
//           return {
//             date: format(statusDate, "MMM d"),
//             sariPatients: formData.healthCenterModel
//               ? formData.healthCenterModel.sariPatients
//               : null,
//             sariPatientsICU: getSariPatientsICU(formData),
//             sariDeathsConfirmed: getSariDeathsConfirmed(formData),
//           };
//         });

//         const xAxisData = graphData.map((entry) => entry.date);

//         const aggregatedSymptoms = {};
//         graphData.forEach((entry) => {
//           if (entry.sariPatients) {
//             if (!aggregatedSymptoms[entry.date]) {
//               aggregatedSymptoms[entry.date] = 0;
//             }
//             aggregatedSymptoms[entry.date] += 1;
//           }
//         });

//         const sortedDates = Object.keys(aggregatedSymptoms).sort(
//           (a, b) => new Date(a) - new Date(b)
//         );

//         const yAxisData = sortedDates.map((date) =>
//           aggregatedSymptoms[date] ? aggregatedSymptoms[date] : 0
//         );

//         const samplesTakenData = graphData.map((entry) =>
//           entry.sariPatientsICU === 0 ? 1 : 0
//         );

//         const sariDeathsConfirmedData = graphData.map((entry) =>
//           entry.sariDeathsConfirmed.toLowerCase().includes("type") ? 1 : 0
//         );

//         setChartData({
//           xAxis: {
//             data: xAxisData,
//             name: "Date",
//             nameLocation: "center",
//             nameGap: 40,
//             nameTextStyle: { fontSize: 16 },
//           },
//           yAxis: {
//             type: "value",
//             name: "Cases Count",
//             nameLocation: "center",
//             nameGap: 40,
//             nameTextStyle: { fontSize: 16 },
//           },
//           series: [
//             {
//               name: "Symptoms Patients",
//               type: "bar",
//               data: yAxisData,
//               itemStyle: { color: "#1f77b4" },
//             },
//             {
//               name: "Samples Taken",
//               type: "bar",
//               data: samplesTakenData,
//               itemStyle: { color: "#2ca02c" },
//             },
//             {
//               name: "Influenza Answer",
//               type: "bar",
//               data: sariDeathsConfirmedData,
//               itemStyle: { color: "#ff7f0e" },
//             },
//           ],
//         });
//       })
//       .catch((error) => {
//         console.error("Error fetching formDataSari:", error);
//       });
//   }, []);

//   const getSariPatientsICU = (formData) => {
//     try {
//       console.log(formData, "formDadfta");
//       const healthCenterModel = JSON.parse(formData.healthCenterModel);
//       return healthCenterModel.sariPatientsICU || null;
//     } catch (error) {
//       console.error("Error parsing sariPatientsICU:", error);
//       return null;
//     }
//   };

//   const getSariDeathsConfirmed = (formData) => {
//     try {
//       console.log(formData, "formDadfta");
//       const healthCenterModel = JSON.parse(formData.healthCenterModel);
//       console.log(healthCenterModel, "formDadfta");
//       return healthCenterModel
//         ? healthCenterModel.sariDeathsConfirmed || ""
//         : "";
//     } catch (error) {
//       console.error("Error parsing sariDeathsConfirmed:", error);
//       return "";
//     }
//   };

//   return (
//     <ReactECharts
//       option={{
//         xAxis: chartData.xAxis,
//         yAxis: chartData.yAxis,
//         series: chartData.series,
//         tooltip: {
//           trigger: "axis",
//           formatter: (params) => {
//             let tooltipContent = params[0].axisValue + "<br/>";
//             params.forEach((param) => {
//               tooltipContent += `${param.seriesName}: ${param.value}<br/>`;
//             });
//             return tooltipContent;
//           },
//         },
//       }}
//       style={{ height: "400px" }}
//     />
//   );
// };

// export default GraphComponent;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import ReactECharts from "echarts-for-react";
import { format } from "date-fns";
import config from "../../config";

const SentinnelGraph = (props) => {
  const { City, Area } = props;
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get(`${config.baseUrl}/formDataSari`);
        const formDataList = response.data[0];
  
        // Filter data based on City and Area
        const filteredChartData = formDataList
          .map((formDataItem) => {
            try {
              const { FORM_DATA, STATUS_DATE } = formDataItem;
              const parsedFormData = JSON.parse(FORM_DATA);
              if (
                parsedFormData &&
                parsedFormData.generalHealthInformationModel
              ) {
                const { city, sectorName } = JSON.parse(
                  parsedFormData?.generalHealthInformationModel
                );
  
                if (
                  // city !== null 
                  ((City === "Both" &&
                    (city === "Vishakapatnam" ||
                      city === "Sonipat" ||city === "SONIPAT"||
                      city === "Vizag" ||
                      city === "VIZAG")) 
                  ||
                    (City === "Vishakapatnam" &&
                      (city === "Vishakapatnam" ||
                        city === City ||
                        city === "VIZAG")) ||
                    (City === "Sonipat" &&
                      (city === "Sonipat" || city === "SONIPAT")))
                ) {
                  console.log(city,City)
                  const { healthCenterModel } = parsedFormData;
                  const parsedHealthCenterModel = JSON.parse(healthCenterModel);
  
                  const sariPatients =
                    parsedHealthCenterModel?.sariPatients || 0;
                  const sariPatientsICU =
                    parsedHealthCenterModel?.sariPatientsICU || 0;
                  const sariDeathsConfirmed =
                    parsedHealthCenterModel?.sariDeathsConfirmed || 0;
  
                  return {
                    date: new Date(STATUS_DATE),
                    sariPatients,
                    sariPatientsICU,
                    sariDeathsConfirmed,
                  };
                }
              }
  
              return null; // Return null for items that don't match the criteria
            } catch (error) {
              console.error(`Error parsing formData:`, error);
              return null;
            }
          })
          .filter((item) => item !== null); // Filter out null entries
  
        // Aggregate data by date
        const aggregatedData = filteredChartData.reduce((acc, item) => {
          const dateKey = format(item.date, "MMM d");
          if (!acc[dateKey]) {
            acc[dateKey] = {
              date: item.date,
              sariPatients: 0,
              sariPatientsICU: 0,
              sariDeathsConfirmed: 0,
            };
          }
          acc[dateKey].sariPatients += item.sariPatients;
          acc[dateKey].sariPatientsICU += item.sariPatientsICU;
          acc[dateKey].sariDeathsConfirmed += item.sariDeathsConfirmed;
          return acc;
        }, {});
  
        const aggregatedChartData = Object.values(aggregatedData);
  
        // Process aggregatedChartData to prepare chart data
        const xAxisData = aggregatedChartData.map((item) =>
          format(item.date, "MMM d")
        );
  
        const seriesData = [
          {
            name: "SARI Patients",
            type: "bar",
            data: aggregatedChartData.map((item) => item.sariPatients),
            itemStyle: { color: "#1f77b4" },
          },
          {
            name: "SARI Patients ICU",
            type: "bar",
            data: aggregatedChartData.map((item) => item.sariPatientsICU),
            itemStyle: { color: "#2ca02c" },
          },
          {
            name: "SARI Deaths Confirmed",
            type: "bar",
            data: aggregatedChartData.map((item) => item.sariDeathsConfirmed),
            itemStyle: { color: "#ff7f0e" },
          },
        ];
  
        // Set chart data in state
        setChartData({
          xAxis: {
            data: xAxisData,
            name: "Date",
            nameLocation: "center",
            nameGap: 40,
            nameTextStyle: { fontSize: 16 },
          },
          yAxis: {
            type: "value",
            name: "Count",
            nameLocation: "center",
            nameGap: 40,
            nameTextStyle: { fontSize: 16 },
          },
          series: seriesData,
        });
      } catch (error) {
        console.error("Error fetching formDataSari:", error);
      }
    };
  
    fetchData(); // Call fetchData on component mount
  }, [City, Area]); // Re-run effect when City or Area change
  
  return (
    <div>
      {/* <h2>SARI Patients Chart</h2> */}

      {/* {console.log(chartData,"djslkjklsnkfnsjfsjdklsfl")} */}
      {chartData ? (
        <ReactECharts
          option={{
            xAxis: chartData.xAxis,
            yAxis: chartData.yAxis,
            series: chartData.series,
            tooltip: {
              trigger: "axis",
              formatter: (params) => {
                let tooltipContent = params[0].axisValue + "<br/>";
                params.forEach((param) => {
                  tooltipContent += `${param.seriesName}: ${param.value}<br/>`;
                });
                return tooltipContent;
              },
            },
          }}
          style={{ height: "400px" }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SentinnelGraph;
