import React, { useState, useEffect } from "react";
import { MDBFooter } from "mdbreact";
import "./Footer.css";

const Copyrights = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const threshold = 50; // Adjust this value based on your preference

      setIsVisible(scrolled > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", isVisible);
  }, [isVisible]);

  const date = new Date().getFullYear();

  return (
    <MDBFooter
      className={`Footer ${isVisible ? "visible" : ""} ${props.className}`}
    >
      <p className="footer-copyright mb-0 py-2">
        &copy; {date} Copyright: <a>Incentive Dashboard</a>
      </p>
    </MDBFooter>
  );
};

export default Copyrights;
