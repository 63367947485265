// import React, { useEffect, useState } from "react";
// import Axios from "axios";
// import ReactECharts from "echarts-for-react";
// // import { format } from "date-fns";
// import config from "../../config";
// import { format, parse } from "date-fns";
// const GraphComponent = (props) => {
//   const { City, Area } = props;

//   const [chartData, setChartData] = useState({
//     xAxis: { data: [] },
//     yAxis: { type: "value", name: "Cases Count" },
//     series: [],
//   });
//   useEffect(() => {
//     Axios.get(`${config.baseUrl}/getHouseholdFormDatagraph`)
//       .then((res) => {
//         const data = res.data.recordset;
//         // console.log(data, "getHouseholdFormDatagraph");

//         // Parse form_data and extract necessary information
//         const lbls = data.map((ele) => {
//           if (ele["form_data"]) {
//             try {
//               return JSON.parse(ele["form_data"].replace(/\\\\/g, "\\"));
//             } catch (error) {
//               console.error("Error parsing form_data:", error);
//               return {};
//             }
//           } else {
//             return {};
//           }
//         });
//         console.log(lbls, "getHouseholdFormDatagraph");

//         // Filter out entries with generalInformation
//         const generalInformationArray =
//           lbls && Array.isArray(lbls)
//             ? lbls.filter(
//                 (item) => item && item !== 0 && item.generalInformation
//               )
//             : [];

//         // Extract generalInformation data
//         const extractedGeneralInfoDataArray = generalInformationArray.map(
//           (item) => item.generalInformation
//         );
//         var aggregatedSymptoms = {};
//         // Extract symptomMembers from generalInformation data

//         const symptomMembersData = extractedGeneralInfoDataArray.map(
//           (symptommembers) => {
//             try {
//               const parsedItem = JSON.parse(symptommembers);

//               const { city, sectorName } = parsedItem;

//               if (
//                 city !== null &&
//                 sectorName !== null &&
//                 ((City === "Both" &&
//                   (city === "Vishakapatnam" ||
//                     city === "Sonipat" ||
//                     city === "Vizag" ||
//                     city === "VIZAG")) ||
//                   (City === "Vishakapatnam" &&
//                     (city === "Vishakapatnam" ||
//                       city === "Vizag" ||
//                       city === "VIZAG")) ||
//                   (City === "Sonipat" &&
//                     (city === "Sonipat" || city === "SONIPAT"))) &&
//                 ((Area === "Both" &&
//                   (sectorName === "Urban" ||
//                     sectorName === "urban" ||
//                     sectorName === "URBAN" ||
//                     sectorName === "Rural" ||
//                     sectorName === "RURAL" ||
//                     sectorName === "rural")) ||
//                   (Area === "Urban" &&
//                     sectorName.toLowerCase() === Area.toLowerCase()) ||
//                   (Area === "Rural" &&
//                     sectorName.toLowerCase() === Area.toLowerCase()))

//                 // ((City === "Both" &&
//                 //   (city === "Vishakapatnam" || city === "Sonipat") &&
//                 //   (sectorName === Area || Area === "Both")) ||
//                 //   (Area === "Both" &&
//                 //     (sectorName === "Urban" || sectorName === "Rural") &&
//                 //     City === city) ||
//                 //   (City !== "Both" &&
//                 //     Area !== "Both" &&
//                 //     city === City &&
//                 //     sectorName === Area))
//               ) {
//                 // console.log(city, City, sectorName, Area);
//                 return parsedItem.symptomsMembers;
//               }
//             } catch (error) {
//               console.error("Error parsing symptomsMembers:", error);
//             }

//             return null; // Return null if conditions are not met or if an error occurs
//           }
//         );

//         // console.log(symptomMembersData, "getHouseholdFormDatagraph");

//         // Prepare data for chart

//         const graphData = data.map((entry) =>
//           format(new Date(entry.status_date), "MMM d")
//         );

//         graphData.forEach((date, index) => {
//           if (!aggregatedSymptoms[date]) {
//             aggregatedSymptoms[date] = 0;
//           }
//           if (symptomMembersData[index] !== null) {
//             aggregatedSymptoms[date] += 1;
//           }
//         });

//         const sortedDates = Object.keys(aggregatedSymptoms).sort(
//           (a, b) => new Date(a) - new Date(b)
//         );
//         const xAxisData = sortedDates;
//         const yAxisData = sortedDates.map((date) => aggregatedSymptoms[date]);
//         // console.log(aggregatedSymptoms,"aggregatedSymptoms",yAxisData)
//         const isTakeSamplesData = {};
//         const influenzaAnswerData = {};

//         // Process each entry to populate isTakeSamplesData and influenzaAnswerData
//         data &&
//           data.forEach((ele) => {
//             if (
//               ele?.status_date &&
//               ele.status_date &&
//               ele?.form_data &&
//               ele.form_data
//             ) {
//               try {
//                 let parseData = JSON.parse(ele?.form_data);

//                 if (parseData && parseData.generalInformation) {
//                   let filteredData = JSON.parse(parseData.generalInformation);
//                   // console.log(filteredData,"G filteredData")
//                   const { city, sectorName } = filteredData;

//                   // console.log(city,City,sectorName,Area ,"slihiofhoihofbeoei")
//                   if (
//                     city !== null &&
//                     sectorName !== null &&
//                     ((City === "Both" &&
//                       (city === "Vishakapatnam" ||
//                         city === "Sonipat" ||
//                         city === "Vizag" ||
//                         city === "VIZAG")) ||
//                       (City === "Vishakapatnam" &&
//                         (city === "Vishakapatnam" ||
//                           city === "Vizag" ||
//                           city === "VIZAG")) ||
//                       (City === "Sonipat" &&
//                         (city === "Sonipat" || city === "SONIPAT"))) &&
//                     ((Area === "Both" &&
//                       (sectorName === "Urban" ||
//                         sectorName === "urban" ||
//                         sectorName === "URBAN" ||
//                         sectorName === "Rural" ||
//                         sectorName === "RURAL" ||
//                         sectorName === "rural")) ||
//                       (Area === "Urban" &&
//                         sectorName.toLowerCase() === Area.toLowerCase()) ||
//                       (Area === "Rural" &&
//                         sectorName.toLowerCase() === Area.toLowerCase()))
//                   ) {
//                     if (
//                       parseData?.houseHoldMemberModel &&
//                       parseData.houseHoldMemberModel
//                     ) {
//                       parseData.houseHoldMemberModel &&
//                         parseData.houseHoldMemberModel.forEach((ele1) => {
//                           let parseData1 = JSON.parse(ele1.generalRespondents);

//                           let dateStr = format(
//                             new Date(ele.status_date),
//                             "MMM d"
//                           );
//                           if (parseData1?.isTakeSamples === 0) {
//                             if (!isTakeSamplesData[dateStr]) {
//                               isTakeSamplesData[dateStr] = 0;
//                             }
//                             isTakeSamplesData[dateStr] += 1;
//                           }
//                           if (
//                             parseData1?.influenzaAnswer &&
//                             parseData1?.influenzaAnswer
//                               .toLowerCase()
//                               .includes("type")
//                           ) {
//                             if (!influenzaAnswerData[dateStr]) {
//                               influenzaAnswerData[dateStr] = 0;
//                             }
//                             influenzaAnswerData[dateStr] += 1;
//                             // console.log(
//                             //   "iskjan",
//                             //   isTakeSamplesData,
//                             //   influenzaAnswerData,
//                             //   sortedDates,
//                             //   yAxisData,
//                             //   xAxisData
//                             // );
//                           }
//                         });
//                     }
//                   }
//                 }
//               } catch (error) {
//                 console.error("Error processing form_data:", error);
//               }
//             }
//           });

//         // console.log(
//         //   "iskjan",
//         //   isTakeSamplesData,
//         //   influenzaAnswerData,
//         //   sortedDates,
//         //   yAxisData,
//         //   xAxisData
//         // );

//         // Calculate samplesTakenData based on isTakeSamplesData
//         const samplesTakenData = sortedDates.map((date) => {
//           // console.log("Samples Taken for", date);
//           const sampleCount = isTakeSamplesData[date]
//             ? isTakeSamplesData[date]
//             : 0;
//           // console.log("Sample Count for", date, ":", sampleCount);
//           return sampleCount;
//         });

//         // Set chart data
//         setChartData({
//           xAxis: {
//             data: xAxisData.slice(1),
//             name: "Date",
//             nameLocation: "center",
//             nameGap: 40,
//             nameTextStyle: {
//               fontSize: 16,
//             },
//           },
//           yAxis: {
//             type: "value",
//             name: "Cases Count",
//             nameLocation: "center",
//             nameGap: 40,
//             nameTextStyle: {
//               fontSize: 16,
//             },
//           },
//           series: [
//             {
//               name: "Symptoms Patients",
//               type: "bar",
//               data: yAxisData.slice(1),
//               itemStyle: {
//                 color: "#1f77b4",
//               },
//             },
//             {
//               name: "Samples Taken",
//               type: "bar",
//               data: samplesTakenData,
//               itemStyle: {
//                 color: "#2ca02c",
//               },
//             },
//             {
//               name: "Influenza Answer",
//               type: "bar",
//               data: sortedDates.map((date) =>
//                 influenzaAnswerData[date] ? influenzaAnswerData[date] : 0
//               ),
//               itemStyle: {
//                 color: "#ff7f0e",
//               },
//             },
//           ],
//         });
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, [City, Area]);
//   // console.log("===", chartData);
//   return (
//     <ReactECharts
//       option={{
//         xAxis: chartData.xAxis,
//         yAxis: chartData.yAxis,
//         series: chartData.series,
//         tooltip: {
//           trigger: "axis",
//           formatter: (params) => {
//             let tooltipContent = params[0].axisValue + "<br/>";
//             params.forEach((param) => {
//               tooltipContent += `${param.seriesName}: ${param.value}<br/>`;
//             });
//             return tooltipContent;
//           },
//         },
//       }}
//       style={{ height: "400px" }}
//     />
//   );
// };

// export default GraphComponent;





















import React, { useEffect, useState } from "react";
import Axios from "axios";
import ReactECharts from "echarts-for-react";
import { format, parse, startOfMonth, endOfMonth, eachMonthOfInterval, isWithinInterval } from "date-fns";
import config from "../../config";

const GraphComponent = (props) => {
  const { City, Area } = props;

  const [chartData, setChartData] = useState({
    xAxis: { data: [] },
    yAxis: { type: "value", name: "Cases Count" },
    series: [],
  });

  useEffect(() => {
    Axios.get(`${config.baseUrl}/getHouseholdFormDatagraph`)
      .then((res) => {
        const data = res.data.recordset;

        // Parse form_data and extract necessary information
        const lbls = data.map((ele) => {
          if (ele["form_data"]) {
            try {
              return JSON.parse(ele["form_data"].replace(/\\\\/g, "\\"));
            } catch (error) {
              console.error("Error parsing form_data:", error);
              return {};
            }
          } else {
            return {};
          }
        });

        // Filter out entries with generalInformation
        const generalInformationArray =
          lbls && Array.isArray(lbls)
            ? lbls.filter(
                (item) => item && item !== 0 && item.generalInformation
              )
            : [];

        // Extract generalInformation data
        const extractedGeneralInfoDataArray = generalInformationArray.map(
          (item) => item.generalInformation
        );
        const aggregatedSymptoms = {};

        // Extract symptomMembers from generalInformation data
        const symptomMembersData = extractedGeneralInfoDataArray.map(
          (symptommembers) => {
            try {
              const parsedItem = JSON.parse(symptommembers);
              const { city, sectorName } = parsedItem;

              if (
                city !== null &&
                sectorName !== null &&
                ((City === "Both" &&
                  (city === "Vishakapatnam" ||
                    city === "Sonipat" ||
                    city === "Vizag" ||
                    city === "VIZAG")) ||
                  (City === "Vishakapatnam" &&
                    (city === "Vishakapatnam" ||
                      city === "Vizag" ||
                      city === "VIZAG")) ||
                  (City === "Sonipat" &&
                    (city === "Sonipat" || city === "SONIPAT"))) &&
                ((Area === "Both" &&
                  (sectorName === "Urban" ||
                    sectorName === "urban" ||
                    sectorName === "URBAN" ||
                    sectorName === "Rural" ||
                    sectorName === "RURAL" ||
                    sectorName === "rural")) ||
                  (Area === "Urban" &&
                    sectorName.toLowerCase() === Area.toLowerCase()) ||
                  (Area === "Rural" &&
                    sectorName.toLowerCase() === Area.toLowerCase()))
              ) {
                return parsedItem.symptomsMembers;
              }
            } catch (error) {
              console.error("Error parsing symptomsMembers:", error);
            }
            return null;
          }
        );

        // Prepare data for chart
        const monthlyData = {};

        data.forEach((entry) => {
          const date = new Date(entry.status_date);
          const monthYear = format(date, "MMMM yyyy");
          if (!monthlyData[monthYear]) {
            monthlyData[monthYear] = 0;
          }
          if (symptomMembersData.length > 0) {
            monthlyData[monthYear] += 1;
          }
        });

        const sortedMonths = Object.keys(monthlyData).sort(
          (a, b) => new Date(a) - new Date(b)
        );
        const xAxisData = sortedMonths;
        const yAxisData = sortedMonths.map((month) => monthlyData[month]);

        // Process each entry to populate isTakeSamplesData and influenzaAnswerData
        const isTakeSamplesData = {};
        const influenzaAnswerData = {};

        data.forEach((ele) => {
          if (
            ele?.status_date &&
            ele.status_date &&
            ele?.form_data &&
            ele.form_data
          ) {
            try {
              let parseData = JSON.parse(ele?.form_data);
              if (parseData && parseData.generalInformation) {
                let filteredData = JSON.parse(parseData.generalInformation);
                const { city, sectorName } = filteredData;
                if (
                  city !== null &&
                  sectorName !== null &&
                  ((City === "Both" &&
                    (city === "Vishakapatnam" ||
                      city === "Sonipat" ||
                      city === "Vizag" ||
                      city === "VIZAG")) ||
                    (City === "Vishakapatnam" &&
                      (city === "Vishakapatnam" ||
                        city === "Vizag" ||
                        city === "VIZAG")) ||
                    (City === "Sonipat" &&
                      (city === "Sonipat" || city === "SONIPAT"))) &&
                  ((Area === "Both" &&
                    (sectorName === "Urban" ||
                      sectorName === "urban" ||
                      sectorName === "URBAN" ||
                      sectorName === "Rural" ||
                      sectorName === "RURAL" ||
                      sectorName === "rural")) ||
                    (Area === "Urban" &&
                      sectorName.toLowerCase() === Area.toLowerCase()) ||
                    (Area === "Rural" &&
                      sectorName.toLowerCase() === Area.toLowerCase()))
                ) {
                  if (
                    parseData?.houseHoldMemberModel &&
                    parseData.houseHoldMemberModel
                  ) {
                    parseData.houseHoldMemberModel.forEach((ele1) => {
                      let parseData1 = JSON.parse(ele1.generalRespondents);
                      let dateStr = format(
                        new Date(ele.status_date),
                        "MMMM yyyy"
                      );
                      if (parseData1?.isTakeSamples === 0) {
                        if (!isTakeSamplesData[dateStr]) {
                          isTakeSamplesData[dateStr] = 0;
                        }
                        isTakeSamplesData[dateStr] += 1;
                      }
                      if (
                        parseData1?.influenzaAnswer &&
                        parseData1?.influenzaAnswer
                          .toLowerCase()
                          .includes("type")
                      ) {
                        if (!influenzaAnswerData[dateStr]) {
                          influenzaAnswerData[dateStr] = 0;
                        }
                        influenzaAnswerData[dateStr] += 1;
                      }
                    });
                  }
                }
              }
            } catch (error) {
              console.error("Error processing form_data:", error);
            }
          }
        });

        // Calculate samplesTakenData based on isTakeSamplesData
        const samplesTakenData = sortedMonths.map((month) => {
          return isTakeSamplesData[month] ? isTakeSamplesData[month] : 0;
        });

        // Set chart data
        setChartData({
          xAxis: {
            data: xAxisData,
            name: "Month",
            nameLocation: "center",
            nameGap: 40,
            nameTextStyle: {
              fontSize: 16,
            },
          },
          yAxis: {
            type: "value",
            name: "Cases Count",
            nameLocation: "center",
            nameGap: 40,
            nameTextStyle: {
              fontSize: 16,
            },
          },
          series: [
            {
              name: "Symptoms Patients",
              type: "bar",
              data: yAxisData,
              itemStyle: {
                color: "#1f77b4",
              },
            },
            {
              name: "Samples Taken",
              type: "bar",
              data: samplesTakenData,
              itemStyle: {
                color: "#2ca02c",
              },
            },
            {
              name: "Influenza Answer",
              type: "bar",
              data: sortedMonths.map((month) =>
                influenzaAnswerData[month] ? influenzaAnswerData[month] : 0
              ),
              itemStyle: {
                color: "#ff7f0e",
              },
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [City, Area]);

  return (
    <ReactECharts
      option={{
        xAxis: chartData.xAxis,
        yAxis: chartData.yAxis,
        series: chartData.series,
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            let tooltipContent = params[0].axisValue + "<br/>";
            params.forEach((param) => {
              tooltipContent += `${param.seriesName}: ${param.value}<br/>`;
            });
            return tooltipContent;
          },
        },
      }}
      style={{ height: "400px" }}
    />
  );
};

export default GraphComponent;

