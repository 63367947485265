import React from "react";
import { Route, Switch } from "react-router-dom";

import DashboardV1 from "./dashboard/v1";
import DashboardV2 from "./dashboard/v2";
// import userCreation from "./userCreation";
import DashboardUserCreat from "./dashboard/DashboardUserCreat";
import UserList from "./dashboard/UserList";
import SearchReports from "./dashboard/SearchReports";
import Test from "./dashboard/demo";
// import SideNavBar from "./dashboard/NewsideNavBar";
// import HouseHoldFormData from "./dashboard/HouseHoldFormData";
import Update from "./dashboard/Update";
import SentinnelGraph from "../../src/components/dashboard/SentinnelGraph";

const fourtOFour = () => <h1 className="text-center">Oops, Page not found.</h1>;

class Routes extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/HouseHoldFormData" exact component={Test} />
          <Route path="/dashboard" exact component={DashboardV2} />
          <Route path="/Update" exact component={Update} />
          {/* <Route path="/SideNavBar" exact component={SideNavBar} /> */}
          <Route
            path="/DashboardUserCreat"
            exact
            component={DashboardUserCreat}
          />
          <Route path="/userlist" exact component={UserList} />
          <Route path="/SentinnelGraph" exact component={SentinnelGraph} />
          {/* <Route path="/searchreports" exact component={SearchReports} /> */}
          {/* <Route path="/graph" exact component={GraphComponent} /> */}
          {/* <Route component={fourtOFour} /> */}
          {/* <Route path="/Update" exact component={Update} /> */}
        </Switch>
      </div>
    );
  }
}

export default Routes;
