import React, { useState, useEffect } from "react";
import GraphDynamicComponent from "./graphDynamicCompont.js";
import "../dashboard/v1.css";
import Axios from "axios";
import config from "../../config";
import { MDBSpinner } from "mdbreact";
import "./GraphBody.css";
// import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';
const GraphsBody = () => {
  const [GraphNumber, setGraphNumber] = useState(0);
  const [typeOfTime1, setTypeOfTime1] = useState("monthly");
  const [GraphData1, setGraphData1] = useState([]);
  const [typeOfTime2, setTypeOfTime2] = useState("monthly");
  const [GraphData2, setGraphData2] = useState([]);
  const [typeOfTime3, setTypeOfTime3] = useState("monthly");
  const [GraphData3, setGraphData3] = useState([]);
  const [typeOfTime4, setTypeOfTime4] = useState("monthly");
  const [GraphData4, setGraphData4] = useState([]);
  const [typeOfTime5, setTypeOfTime5] = useState("monthly");
  const [GraphData5, setGraphData5] = useState([]);
  const [typeOfTime6, setTypeOfTime6] = useState("monthly");
  const [GraphData6, setGraphData6] = useState([]);
  const [typeOfTime7, setTypeOfTime7] = useState("monthly");
  const [GraphData7, setGraphData7] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAgeForLILPatients = async () => {
    //let  type = typeOfTime;
    if (GraphNumber === 1) {
      var type = typeOfTime1;
    } else if (GraphNumber === 2) {
      var type = typeOfTime2;
    } else if (GraphNumber === 3) {
      var type = typeOfTime3;
    } else if (GraphNumber === 4) {
      var type = typeOfTime4;
    } else if (GraphNumber === 5) {
      var type = typeOfTime4;
    } else if (GraphNumber === 6) {
      var type = typeOfTime4;
    } else {
      var type = "monthly";
    }
    try {
      const response = await Axios.get(`${config.baseUrl}/householdData`);

      if (Array.isArray(response.data.recordset)) {
        const formattedData = response.data.recordset
          .map((item) => ({
            CREATED_DATE: item.CREATED_DATE,
            STATUS_DATE: item.STATUS_DATE,
            FORM_DATA: item.FORM_DATA
              ? JSON.parse(item.FORM_DATA).generalInformation
                ? JSON.parse(JSON.parse(item.FORM_DATA).generalInformation)
                : null
              : null,
            houseHoldMemberModel: item.FORM_DATA
              ? JSON.parse(item.FORM_DATA).houseHoldMemberModel
              : null,
          }))
          .filter((item) => item.FORM_DATA);

        const getDateKey = (dateString) => {
          const date = new Date(dateString);
          if (type === "monthly") {
            const month = date.toLocaleString("default", { month: "long" });
            const year = date.getFullYear();
            return `${month} ${year}`;
          } else if (type === "weekly") {
            const week = Math.ceil(date.getDate() / 7);
            const month = date.toLocaleString("default", { month: "long" });
            const year = date.getFullYear();
            return `Week ${week} ${month} ${year}`;
          }
        };

        const interviewsByDateKey = {};
        formattedData.forEach((interview) => {
          const dateKey = getDateKey(interview.CREATED_DATE);
          if (!interviewsByDateKey[dateKey]) {
            interviewsByDateKey[dateKey] = [];
          }
          interviewsByDateKey[dateKey].push(interview);
        });

        const participantConsentCountByDateKey = {};
        const symptomsMembersCountByDateKey = {};
        const patientsCountCountByDateKey = {};
        const TotalsymptomsCountCountByDateKey = {};
        const countIsTakeSamplesZeroCountByDataKey = {};
        const totalcountIsTakeSamplesZeroCountByDataKey = {};
        const totalIsInfluenzaAffectedCountByDataKey = {};
        const IsInfluenzaAffectedZeroCountByDataKey = {};
        const HouseMemberCountCountByDataKey = {};
        const totalIsInfluenzaAffectedHouseHoldPostiveCountByDataKey = {};
        const totalIsInfluenzaAffectedHouseHoldCountByDataKey = {};
        const totalIsInfluenzaAffectedAwaitCountByDataKey = {};
        for (const dateKey in interviewsByDateKey) {
          const interviewsInDateKey = interviewsByDateKey[dateKey];
          // const consentCount = interviewsInDateKey.filter(
          //   (interview) => interview.FORM_DATA.participantConsent === "Yes"
          // ).length;
          const consentCount = interviewsInDateKey.reduce(
            (count, interview) => {
              const formData = interview.FORM_DATA;
              if (formData && formData.participantConsent === "Yes") {
                count++;
              }
              return count;
            },
            0
          );

          // const HouseMemberCount = interviewsInDateKey.filter(
          //   (interview) => interview.FORM_DATA.householdMembers
          // );

          const HouseMemberCount = interviewsInDateKey.reduce(
            (count, interview) => {
              if (
                interview.FORM_DATA.householdMembers &&
                interview.FORM_DATA.householdMembers > 0
              ) {
                return count + interview.FORM_DATA.householdMembers;
              }
              return count;
            },
            0
          );

          const symptomsCount = interviewsInDateKey.filter(
            (interview) =>
              interview.FORM_DATA.symptomsMembers &&
              interview.FORM_DATA.symptomsMembers > 0
          ).length;
          const patientsCount = interviewsInDateKey.filter(
            (interview) =>
              interview.houseHoldMemberModel && interview.houseHoldMemberModel
          ).length;

          const TotalsymptomsCount = interviewsInDateKey.reduce(
            (count, interview) => {
              if (
                interview.FORM_DATA.symptomsMembers &&
                interview.FORM_DATA.symptomsMembers > 0
              ) {
                return count + interview.FORM_DATA.symptomsMembers;
              }
              return count;
            },
            0
          );

          const countIsTakeSamplesZero = interviewsInDateKey.reduce(
            (count, interview) => {
              const houseHoldMemberModel = interview.houseHoldMemberModel;
              if (houseHoldMemberModel) {
                houseHoldMemberModel.forEach((member) => {
                  const generalRespondents = JSON.parse(
                    member.generalRespondents
                  );
                  if (generalRespondents.isTakeSamples === 0) {
                    count++;
                  }
                });
              }
              return count;
            },
            0
          );

          const totalcountIsTakeSamplesZero = interviewsInDateKey.reduce(
            (count, interview) => {
              const houseHoldMemberModel = interview.houseHoldMemberModel;
              if (houseHoldMemberModel) {
                houseHoldMemberModel.forEach((member) => {
                  const generalRespondents = JSON.parse(
                    member.generalRespondents
                  );
                  if (
                    generalRespondents.isTakeSamples === 0 ||
                    generalRespondents.isTakeSamples === 1
                  ) {
                    count++;
                  }
                });
              }
              return count;
            },
            0
          );

          const totalIsInfluenzaAffected = interviewsInDateKey.reduce(
            (count, interview) => {
              const houseHoldMemberModel = interview.houseHoldMemberModel;
              if (houseHoldMemberModel) {
                houseHoldMemberModel.forEach((member) => {
                  const generalRespondents = JSON.parse(
                    member.generalRespondents
                  );
                  if (
                    generalRespondents.isInfluenzaAffected === 0 ||
                    generalRespondents.isInfluenzaAffected === 1
                  ) {
                    count++;
                  }
                });
              }
              return count;
            },
            0
          );
          const totalIsInfluenzaAffectedAwait = interviewsInDateKey.reduce(
            (count, interview) => {
              const houseHoldMemberModel = interview.houseHoldMemberModel;
              if (houseHoldMemberModel) {
                houseHoldMemberModel.forEach((member) => {
                  const generalRespondents = JSON.parse(
                    member.generalRespondents
                  );
                  if (
                    generalRespondents.isInfluenzaAffected !== 0 ||
                    generalRespondents.isInfluenzaAffected !== 1
                  ) {
                    count++;
                  }
                });
              }
              return count;
            },
            0
          );
          const totalIsInfluenzaAffectedHouseHoldPostive =
            interviewsInDateKey.reduce((count, interview) => {
              const formData = interview.FORM_DATA;

              if (formData && formData.participantConsent === "Yes") {
                const houseHoldMemberModel = interview.houseHoldMemberModel;

                if (houseHoldMemberModel) {
                  for (let member of houseHoldMemberModel) {
                    const generalRespondents = JSON.parse(
                      member.generalRespondents
                    );

                    if (generalRespondents.isInfluenzaAffected === 0) {
                      count++;
                      break;
                    }
                  }
                }
              }
              return count;
            }, 0);
          const totalIsInfluenzaAffectedHouseHold = interviewsInDateKey.reduce(
            (count, interview) => {
              const formData = interview.FORM_DATA;

              if (formData && formData.participantConsent === "Yes") {
                const houseHoldMemberModel = interview.houseHoldMemberModel;

                if (houseHoldMemberModel) {
                  for (let member of houseHoldMemberModel) {
                    const generalRespondents = JSON.parse(
                      member.generalRespondents
                    );

                    if (
                      generalRespondents.isInfluenzaAffected === 0 ||
                      generalRespondents.isInfluenzaAffected === 1
                    ) {
                      count++;
                      break;
                    }
                  }
                }
              }
              return count;
            },
            0
          );

          const IsInfluenzaAffectedZero = interviewsInDateKey.reduce(
            (count, interview) => {
              const houseHoldMemberModel = interview.houseHoldMemberModel;
              if (houseHoldMemberModel) {
                houseHoldMemberModel.forEach((member) => {
                  const generalRespondents = JSON.parse(
                    member.generalRespondents
                  );
                  if (generalRespondents.isInfluenzaAffected === 0) {
                    count++;
                  }
                });
              }
              return count;
            },
            0
          );

          participantConsentCountByDateKey[dateKey] = consentCount;
          symptomsMembersCountByDateKey[dateKey] = symptomsCount;
          patientsCountCountByDateKey[dateKey] = patientsCount;
          TotalsymptomsCountCountByDateKey[dateKey] = TotalsymptomsCount;
          countIsTakeSamplesZeroCountByDataKey[dateKey] =
            countIsTakeSamplesZero;
          totalcountIsTakeSamplesZeroCountByDataKey[dateKey] =
            totalcountIsTakeSamplesZero;
          totalIsInfluenzaAffectedCountByDataKey[dateKey] =
            totalIsInfluenzaAffected;
          IsInfluenzaAffectedZeroCountByDataKey[dateKey] =
            IsInfluenzaAffectedZero;
          HouseMemberCountCountByDataKey[dateKey] = HouseMemberCount;
          totalIsInfluenzaAffectedHouseHoldPostiveCountByDataKey[dateKey] =
            totalIsInfluenzaAffectedHouseHoldPostive;
          totalIsInfluenzaAffectedHouseHoldCountByDataKey[dateKey] =
            totalIsInfluenzaAffectedHouseHold;
          totalIsInfluenzaAffectedAwaitCountByDataKey[dateKey] =
            totalIsInfluenzaAffectedAwait;
        }

        const dateKeys = Object.keys(participantConsentCountByDateKey);

        const participantConsentCounts = Object.values(
          participantConsentCountByDateKey
        );

        const symptomsMembersCounts = Object.values(
          symptomsMembersCountByDateKey
        );

        const patientsCountCount = Object.values(patientsCountCountByDateKey);

        const TotalsymptomsCountCount = Object.values(
          TotalsymptomsCountCountByDateKey
        );

        const countIsTakeSamplesZeroCount = Object.values(
          countIsTakeSamplesZeroCountByDataKey
        );

        const totalcountIsTakeSamplesZeroCount = Object.values(
          totalcountIsTakeSamplesZeroCountByDataKey
        );

        const totalIsInfluenzaAffectedCount = Object.values(
          totalIsInfluenzaAffectedCountByDataKey
        );

        const IsInfluenzaAffectedZeroCount = Object.values(
          IsInfluenzaAffectedZeroCountByDataKey
        );

        const HouseMemberCountCountByDataKeyCount = Object.values(
          HouseMemberCountCountByDataKey
        );
        const totalIsInfluenzaAffectedHouseHoldPostiveCount = Object.values(
          totalIsInfluenzaAffectedHouseHoldPostiveCountByDataKey
        );
        const totalIsInfluenzaAffectedHouseHoldCount = Object.values(
          totalIsInfluenzaAffectedHouseHoldCountByDataKey
        );
        const totalIsInfluenzaAffectedAwaitCount = Object.values(
          totalIsInfluenzaAffectedAwaitCountByDataKey
        );

        const result = {
          dateKey: dateKeys,
          participantConsentCount: participantConsentCounts,
          symptomsMembersCount: symptomsMembersCounts,
          patientsCount: patientsCountCount,
          TotalsymptomsCount: TotalsymptomsCountCount,
          IsTakeSamplesPostiveCount: countIsTakeSamplesZeroCount,
          IsTakeSamplesTotalCount: totalcountIsTakeSamplesZeroCount,
          IsInfluenzaAffectedTotalCount: totalIsInfluenzaAffectedCount,
          IsInfluenzaAffectedPostiveCount: IsInfluenzaAffectedZeroCount,
          HouseMemberCount: HouseMemberCountCountByDataKeyCount,
          totalIsInfluenzaAffectedHouseHoldPostiveCount:
            totalIsInfluenzaAffectedHouseHoldPostiveCount,
          totalIsInfluenzaAffectedHouseHoldCount:
            totalIsInfluenzaAffectedHouseHoldCount,
          totalIsInfluenzaAffectedAwaitCount:
            totalIsInfluenzaAffectedAwaitCount,
        };
        // console.log(result, "dshjkhskdfkja");
        if (GraphNumber === 1) {
          setGraphData1(result);
        } else if (GraphNumber === 2) {
          setGraphData2(result);
        } else if (GraphNumber === 3) {
          setGraphData3(result);
        } else if (GraphNumber === 4) {
          setGraphData4(result);
        } else if (GraphNumber === 5) {
          setGraphData5(result);
        } else if (GraphNumber === 6) {
          setGraphData6(result);
        } else {
          setGraphData1(result);
          setGraphData2(result);
          setGraphData3(result);
          setGraphData4(result);
          setGraphData5(result);
          setGraphData6(result);
        }
        return result;
      } else {
        console.error("Invalid data format:", response.data);
        return [];
      }
    } catch (error) {
      console.error("Error fetching household data:", error);
      return [];
    }
  };

  const TypeOfTimeBased = (type, GraphNumber) => {
    if (GraphNumber === 1) {
      setTypeOfTime1(type);
      setGraphNumber(GraphNumber);
    } else if (GraphNumber === 2) {
      setTypeOfTime2(type);
      setGraphNumber(GraphNumber);
    } else if (GraphNumber === 3) {
      setTypeOfTime3(type);
      setGraphNumber(GraphNumber);
    } else if (GraphNumber === 4) {
      setTypeOfTime4(type);
      setGraphNumber(GraphNumber);
    } else if (GraphNumber === 5) {
      setTypeOfTime5(type);
      setGraphNumber(GraphNumber);
    } else if (GraphNumber === 6) {
      setTypeOfTime6(type);
      setGraphNumber(GraphNumber);
    } else {
      setTypeOfTime1(type);
      setTypeOfTime2(type);
      setTypeOfTime3(type);
      setTypeOfTime4(type);
      setTypeOfTime5(type);
      setTypeOfTime6(type);
      setGraphNumber(0);
    }
  };

  useEffect(() => {
    if (
      typeOfTime1 ||
      typeOfTime2 ||
      typeOfTime3 ||
      typeOfTime4 ||
      typeOfTime5 ||
      typeOfTime6
    ) {
      getAgeForLILPatients();
    }
  }, [
    typeOfTime1,
    typeOfTime2,
    typeOfTime3,
    typeOfTime4,
    typeOfTime5,
    typeOfTime6,
  ]);
  return (
    <div>
      <div className="RowComponent">
        <h3 class="pageheading">
          ILI symptomatic patients is agreed to participate{" "}
        </h3>
        <div className="DynamicGraphList">
          <div className="ILIsymptomaticpatients">
            <div className="headding">
              <h1>HouseHold Level </h1>
              <div>
                <button
                  onClick={() => TypeOfTimeBased("weekly", 1)}
                  style={{
                    backgroundColor:
                      typeOfTime1 === "weekly" ? "#ff842d" : null,
                  }}
                >
                  Weekly
                </button>
                <button
                  onClick={() => TypeOfTimeBased("monthly", 1)}
                  style={{
                    backgroundColor:
                      typeOfTime1 === "monthly" ? "#ff842d" : null,
                  }}
                >
                  Monthly
                </button>
              </div>
            </div>
            {loading ? (
              <div
                className="text-center mt-5"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "46vh",
                }}
              >
                <MDBSpinner small />
              </div>
            ) : (
              <GraphDynamicComponent
                keys={GraphData1.dateKey}
                FirstValue={GraphData1.participantConsentCount}
                SecondValue={GraphData1.symptomsMembersCount}
                FirstKey={"participantConsentCount"}
                SecondKey={"symptomsMembersCount"}
              />
            )}
          </div>
          <div className="PatientsTestedInfluenza">
            <div className="headding">
              <h1>Patients Level</h1>
              <div>
                <button
                  onClick={() => TypeOfTimeBased("weekly", 2)}
                  style={{
                    backgroundColor:
                      typeOfTime2 === "weekly" ? "#ff842d" : null,
                  }}
                >
                  Weekly
                </button>
                <button
                  onClick={() => TypeOfTimeBased("monthly", 2)}
                  style={{
                    backgroundColor:
                      typeOfTime2 === "monthly" ? "#ff842d" : null,
                  }}
                >
                  Monthly
                </button>
              </div>
            </div>

            <GraphDynamicComponent
              keys={GraphData2.dateKey}
              FirstValue={GraphData2.HouseMemberCount}
              SecondValue={GraphData2.patientsCount}
              FirstKey={"HouseMemberCount"}
              SecondKey={"patientsCount"}
            />
          </div>
        </div>
      </div>
      <div className="RowComponent">
        <h3 class="pageheading">Patients tested for influenza </h3>
        <div className="DynamicGraphList">
          <div className="ILIsymptomaticpatients">
            <div className="headding">
              <h1>HouseHold Level </h1>
              <div>
                <button
                  onClick={() => TypeOfTimeBased("weekly", 3)}
                  style={{
                    backgroundColor:
                      typeOfTime3 === "weekly" ? "#ff842d" : null,
                  }}
                >
                  Weekly
                </button>
                <button
                  onClick={() => TypeOfTimeBased("monthly", 3)}
                  style={{
                    backgroundColor:
                      typeOfTime3 === "monthly" ? "#ff842d" : null,
                  }}
                >
                  Monthly
                </button>
              </div>
            </div>

            <GraphDynamicComponent
              keys={GraphData3.dateKey}
              FirstValue={GraphData3.participantConsentCount}
              SecondValue={GraphData3.totalIsInfluenzaAffectedHouseHoldCount}
              FirstKey={"participantConsentCount"}
              SecondKey={"totalIsInfluenzaAffectedHouseHoldCount"}
            />
          </div>
          <div className="PatientsTestedInfluenza">
            <div className="headding">
              <h1>Patients Level</h1>
              <div>
                <button
                  onClick={() => TypeOfTimeBased("weekly", 4)}
                  style={{
                    backgroundColor:
                      typeOfTime4 === "weekly" ? "#ff842d" : null,
                  }}
                >
                  Weekly
                </button>
                <button
                  onClick={() => TypeOfTimeBased("monthly", 4)}
                  style={{
                    backgroundColor:
                      typeOfTime4 === "monthly" ? "#ff842d" : null,
                  }}
                >
                  Monthly
                </button>
              </div>
            </div>

            <GraphDynamicComponent
              keys={GraphData4.dateKey}
              FirstValue={GraphData4.patientsCount}
              SecondValue={GraphData4.IsInfluenzaAffectedTotalCount}
              FirstKey={"patientsCount"}
              SecondKey={"IsInfluenzaAffectedTotalCount"}
            />
          </div>
        </div>
      </div>
      {/* {console.log(GraphData5, GraphData6,"typeOfTime5, typeOfTime6")} */}
      <div className="RowComponent">
        <h3 class="pageheading">Patients positive for influenza </h3>
        <div className="DynamicGraphList">
          <div className="ILIsymptomaticpatients">
            <div className="headding">
              <h1>HouseHold Level </h1>
              <div>
                <button
                  onClick={() => TypeOfTimeBased("weekly", 5)}
                  style={{
                    backgroundColor:
                      typeOfTime5 === "weekly" ? "#ff842d" : null,
                  }}
                >
                  Weekly
                </button>
                <button
                  onClick={() => TypeOfTimeBased("monthly", 5)}
                  style={{
                    backgroundColor:
                      typeOfTime5 === "monthly" ? "#ff842d" : null,
                  }}
                >
                  Monthly
                </button>
              </div>
            </div>

            <GraphDynamicComponent
              keys={GraphData5.dateKey}
              FirstValue={GraphData5.participantConsentCount}
              SecondValue={
                GraphData5.totalIsInfluenzaAffectedHouseHoldPostiveCount
              }
              FirstKey={"participantConsentCount"}
              SecondKey={"IsInfluenzaAffectedHouseHoldPostiveCount"}
            />
          </div>
          <div className="PatientsTestedInfluenza">
            <div className="headding">
              <h1>Patients Level</h1>
              <div>
                <button
                  onClick={() => TypeOfTimeBased("weekly", 6)}
                  style={{
                    backgroundColor:
                      typeOfTime6 === "weekly" ? "#ff842d" : null,
                  }}
                >
                  Weekly
                </button>
                <button
                  onClick={() => TypeOfTimeBased("monthly", 6)}
                  style={{
                    backgroundColor:
                      typeOfTime6 === "monthly" ? "#ff842d" : null,
                  }}
                >
                  Monthly
                </button>
              </div>
            </div>

            <GraphDynamicComponent
              keys={GraphData6.dateKey}
              FirstValue={GraphData6.patientsCount}
              SecondValue={GraphData6.IsInfluenzaAffectedPostiveCount}
              FirstKey={"patientsCount"}
              SecondKey={"IsInfluenzaAffectedPostiveCount"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphsBody;
